import { TaxConfig } from "app/util/tax.config";

export class TaxRebateClass
{
  constructor(
    private _LifeInsurancePolicyValue: number,
    private _LifeInsurancePremiumAmount: number,
    private _LifeInsurancePremiumAllowed: number,
    private _MaxLimiteOfdeposit: number,

    private _ContributionToDepositPensionSchemeAmount: number,
    private _ContributionToDepositPensionSchemeAllowed: number,

    private _InvestmentInApprovedSavingsCertificateAmount: number,
    private _InvestmentInApprovedSavingsCertificateAllowed: number,

    private _InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount: number,
    private _InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed: number,

    private _ContributionToProvidentFundToWhichProvidentFundAmount: number,
    private _ContributionToProvidentFundToWhichProvidentFundAllowed: number,


    private _SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount: number,
    private _SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed: number,

    private _ContributionToSuperAnnuationFundAmount: number,
    private _ContributionToSuperAnnuationFundAllowed: number,

    private _ContributionToBenevolentFundAndGroupInsurancePremiumAmount: number,
    private _ContributionToBenevolentFundAndGroupInsurancePremiumAllowed: number,

    private _InvestmentInBangladeshGovtTreasuryBondAmount: number,
    private _InvestmentInBangladeshGovtTreasuryBondAllowed: number,

    private _MutualFundAmount: number,
    private _MutualFundAllowed: number,

    private _ContributionToZakatFundAmount: number,
    private _ContributionToZakatFundAllowed: number,

    private _DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount: number,
    private _DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed: number,

    private _DonationToCharitableHospitalRecognizedByNBRAmount: number,
    private _DonationToCharitableHospitalRecognizedByNBRAllowed: number,

    private _DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount: number,
    private _DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed: number,

    private _ContributionToLiberationWarMusuemAmount: number,
    private _ContributionToLiberationWarMusuemAllowed: number,

    private _ContributionToAgaKahanDeveleopmentNetworkAmount: number,
    private _ContributionToAgaKahanDeveleopmentNetworkAllowed: number,

    private _DoantionToAhsaniaCancerHospitalAmount: number,
    private _DoantionToAhsaniaCancerHospitalAllowed: number,

    private _DonationToICDDRBAmount: number,
    private _DonationToICDDRBAllowed: number,

    private _DonationToCentreForRehabilitationOfParalyzedAmount: number,
    private _DonationToCentreForRehabilitationOfParalyzedAllowed: number,

    private _DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount: number,
    private _DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed: number,

    private _ContributionToAsiatecSocietyBangladeshAmount: number,
    private _ContributionToAsiatecSocietyBangladeshAllowed: number,

    private _DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount: number,
    private _DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed: number,



    private _TotalInvestmentAmount: number,
    private _TotalInvestmentAllowed: number,
  )
  {
    this.taxConfig = new TaxConfig();
    this.taxConfig.MaxLimiteOfdeposit = 60000;
  }
  taxConfig: TaxConfig;

  public get MaxLimiteOfdeposit(): number
  {
    return this._MaxLimiteOfdeposit = this.taxConfig.MaxLimiteOfdeposit;
  }
  public set MaxLimiteOfdeposit(x: number)
  {
    this._MaxLimiteOfdeposit = x;
  }

  public get LifeInsurancePolicyValue(): number
  {
    return this._LifeInsurancePolicyValue;
  }
  public set LifeInsurancePolicyValue(x: number)
  {
    this._LifeInsurancePolicyValue = x;
  }

  public get LifeInsurancePremiumAmount(): number
  {
    return this._LifeInsurancePremiumAmount;
  }
  public set LifeInsurancePremiumAmount(x: number)
  {
    this._LifeInsurancePremiumAmount = x;
  }

  public get LifeInsurancePremiumAllowed(): number
  {
    return this._LifeInsurancePremiumAllowed = Math.min(Number(this.LifeInsurancePolicyValue) * .10, Number(this.LifeInsurancePremiumAmount));
  }
  public set LifeInsurancePremiumAllowed(x: number)
  {
    this._LifeInsurancePremiumAllowed = x;
  }

  public get ContributionToDepositPensionSchemeAmount(): number
  {
    return this._ContributionToDepositPensionSchemeAmount;
  }
  public set ContributionToDepositPensionSchemeAmount(x: number)
  {
    this._ContributionToDepositPensionSchemeAmount = x;
  }

  public get ContributionToDepositPensionSchemeAllowed(): number
  {
    return this._ContributionToDepositPensionSchemeAllowed = this.ContributionToDepositPensionSchemeAmount > this.MaxLimiteOfdeposit ? this.MaxLimiteOfdeposit : this.ContributionToDepositPensionSchemeAmount;
  }
  public set ContributionToDepositPensionSchemeAllowed(x: number)
  {
    this._ContributionToDepositPensionSchemeAllowed = x;
  }

  public get InvestmentInApprovedSavingsCertificateAmount(): number
  {
    return this._InvestmentInApprovedSavingsCertificateAmount;
  }
  public set InvestmentInApprovedSavingsCertificateAmount(x: number)
  {
    this._InvestmentInApprovedSavingsCertificateAmount = x;
  }

  public get InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount(): number
  {
    return this._InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount;
  }
  public set InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount(x: number)
  {
    this._InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = x;
  }

  public get ContributionToProvidentFundToWhichProvidentFundAmount(): number
  {
    return this._ContributionToProvidentFundToWhichProvidentFundAmount;
  }
  public set ContributionToProvidentFundToWhichProvidentFundAmount(x: number)
  {
    this._ContributionToProvidentFundToWhichProvidentFundAmount = x;
  }

  public get SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount(): number
  {
    return this._SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount;
  }
  public set SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount(x: number)
  {
    this._SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = x;
  }

  public get ContributionToSuperAnnuationFundAmount(): number
  {
    return this._ContributionToSuperAnnuationFundAmount;
  }
  public set ContributionToSuperAnnuationFundAmount(x: number)
  {
    this._ContributionToSuperAnnuationFundAmount = x;
  }


  public get ContributionToBenevolentFundAndGroupInsurancePremiumAmount(): number
  {
    return this._ContributionToBenevolentFundAndGroupInsurancePremiumAmount;
  }
  public set ContributionToBenevolentFundAndGroupInsurancePremiumAmount(x: number)
  {
    this._ContributionToBenevolentFundAndGroupInsurancePremiumAmount = x;
  }

  public get InvestmentInBangladeshGovtTreasuryBondAmount(): number
  {
    return this._InvestmentInBangladeshGovtTreasuryBondAmount;
  }
  public set InvestmentInBangladeshGovtTreasuryBondAmount(x: number)
  {
    this._InvestmentInBangladeshGovtTreasuryBondAmount = x;
  }

  public get MutualFundAmount(): number
  {
    return this._MutualFundAmount;
  }
  public set MutualFundAmount(x: number)
  {
    this._MutualFundAmount = x;
  }

  public get ContributionToZakatFundAmount(): number
  {
    return this._ContributionToZakatFundAmount;
  }
  public set ContributionToZakatFundAmount(x: number)
  {
    this._ContributionToZakatFundAmount = x;
  }

  public get DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount(): number
  {
    return this._DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount;
  }
  public set DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount(x: number)
  {
    this._DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = x;
  }

  public get DonationToCharitableHospitalRecognizedByNBRAmount(): number
  {
    return this._DonationToCharitableHospitalRecognizedByNBRAmount;
  }
  public set DonationToCharitableHospitalRecognizedByNBRAmount(x: number)
  {
    this._DonationToCharitableHospitalRecognizedByNBRAmount = x;
  }

  public get DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount(): number
  {
    return this._DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount;
  }
  public set DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount(x: number)
  {
    this._DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = x;
  }

  public get ContributionToLiberationWarMusuemAmount(): number
  {
    return this._ContributionToLiberationWarMusuemAmount;
  }
  public set ContributionToLiberationWarMusuemAmount(x: number)
  {
    this._ContributionToLiberationWarMusuemAmount = x;
  }

  public get ContributionToAgaKahanDeveleopmentNetworkAmount(): number
  {
    return this._ContributionToAgaKahanDeveleopmentNetworkAmount;
  }
  public set ContributionToAgaKahanDeveleopmentNetworkAmount(x: number)
  {
    this._ContributionToAgaKahanDeveleopmentNetworkAmount = x;
  }

  public get DoantionToAhsaniaCancerHospitalAmount(): number
  {
    return this._DoantionToAhsaniaCancerHospitalAmount;
  }
  public set DoantionToAhsaniaCancerHospitalAmount(x: number)
  {
    this._DoantionToAhsaniaCancerHospitalAmount = x;
  }

  public get DonationToICDDRBAmount(): number
  {
    return this._DonationToICDDRBAmount;
  }
  public set DonationToICDDRBAmount(x: number)
  {
    this._DonationToICDDRBAmount = x;
  }

  public get DonationToCentreForRehabilitationOfParalyzedAmount(): number
  {
    return this._DonationToCentreForRehabilitationOfParalyzedAmount;
  }
  public set DonationToCentreForRehabilitationOfParalyzedAmount(x: number)
  {
    this._DonationToCentreForRehabilitationOfParalyzedAmount = x;
  }

  public get DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount(): number
  {
    return this._DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount;
  }
  public set DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount(x: number)
  {
    this._DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = x;
  }

  public get ContributionToAsiatecSocietyBangladeshAmount(): number
  {
    return this._ContributionToAsiatecSocietyBangladeshAmount;
  }
  public set ContributionToAsiatecSocietyBangladeshAmount(x: number)
  {
    this._ContributionToAsiatecSocietyBangladeshAmount = x;
  }


  public get DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount(): number
  {
    return this._DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount;
  }
  public set DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount(x: number)
  {
    this._DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = x;
  }

  public get TotalInvestmentAmount(): number
  {
    return this._TotalInvestmentAmount = Number(this.LifeInsurancePremiumAmount)
      + Number(this.ContributionToDepositPensionSchemeAmount)
      + Number(this.InvestmentInApprovedSavingsCertificateAmount)
      + Number(this.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount)
      + Number(this.ContributionToProvidentFundToWhichProvidentFundAmount)
      + Number(this.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount)
      + Number(this.ContributionToSuperAnnuationFundAmount)
      + Number(this.ContributionToBenevolentFundAndGroupInsurancePremiumAmount)
      + Number(this.InvestmentInBangladeshGovtTreasuryBondAmount)
      + Number(this.MutualFundAmount)
      + Number(this.ContributionToZakatFundAmount)
      + Number(this.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount)
      + Number(this.DonationToCharitableHospitalRecognizedByNBRAmount)
      + Number(this.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount)
      + Number(this.ContributionToLiberationWarMusuemAmount)
      + Number(this.ContributionToAgaKahanDeveleopmentNetworkAmount)
      + Number(this.DoantionToAhsaniaCancerHospitalAmount)
      + Number(this.DonationToICDDRBAmount)
      + Number(this.DonationToCentreForRehabilitationOfParalyzedAmount)
      + Number(this.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount)
      + Number(this.ContributionToAsiatecSocietyBangladeshAmount)
      + Number(this.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount);
  }
  public set TotalInvestmentAmount(x: number)
  {
    this._TotalInvestmentAmount = x;
  }

  public get TotalInvestmentAllowed(): number
  {
    return this._TotalInvestmentAllowed = Number(this.LifeInsurancePremiumAllowed)
      + Number(this.ContributionToDepositPensionSchemeAllowed)
      + Number(this._InvestmentInApprovedSavingsCertificateAmount)
      + Number(this.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount)
      + Number(this.ContributionToProvidentFundToWhichProvidentFundAmount)
      + Number(this.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount)
      + Number(this.ContributionToSuperAnnuationFundAmount)
      + Number(this.ContributionToBenevolentFundAndGroupInsurancePremiumAmount)
      + Number(this.InvestmentInBangladeshGovtTreasuryBondAmount)
      + Number(this.MutualFundAmount)
      + Number(this.ContributionToZakatFundAmount)
      + Number(this.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount)
      + Number(this.DonationToCharitableHospitalRecognizedByNBRAmount)
      + Number(this.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount)
      + Number(this.ContributionToLiberationWarMusuemAmount)
      + Number(this.ContributionToAgaKahanDeveleopmentNetworkAmount)
      + Number(this.DoantionToAhsaniaCancerHospitalAmount)
      + Number(this.DonationToICDDRBAmount)
      + Number(this.DonationToCentreForRehabilitationOfParalyzedAmount)
      + Number(this.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount)
      + Number(this.ContributionToAsiatecSocietyBangladeshAmount)
      + Number(this.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount);
  }
  public set TotalInvestmentAllowed(x: number)
  {
    this._TotalInvestmentAllowed = x;
  }
}
