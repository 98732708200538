import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseControl } from 'app/models/dynamic-form';
import { SalaryClass } from './Salary.Class';
import { SalaryService } from './Salary.service';
import { bangla, english } from 'app/util/locale';

@Component({
    selector: 'p-Salary-form',
    templateUrl: './p-Salary-form.component.html',
    styleUrls: ['./Salary.component.scss']

})
export class Private_SalaryFormControlComponent
{
    @Input() control: BaseControl<string>;
    @Input() form: FormGroup;
    @Input() CheckIfDisabled: any;
    amountpaid: any;
    lang = '';
    receivedAnyHouse: boolean;
    DateOfBirth: any;

    checkDisabled: any;
    IsCurrentYear: any;

    constructor(
        private _salaryService: SalaryService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

    )
    {
        // this.form.controls["IsDisabledPerson"].disabled;
        // this.form.controls['IsDisabledPerson'].disable();
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.form.disable();
        }
    }

    checkChange(event, key)
    {
        let rawForm = this.form.getRawValue();

        let BasicPayAmount = null;

        rawForm.BasicPayAmount == '' ?
            BasicPayAmount = 0 : BasicPayAmount = rawForm.BasicPayAmount;
        BasicPayAmount = Number(BasicPayAmount);

        let SpecialPayAmount = null;

        rawForm.SpecialPayAmount == '' ?
            SpecialPayAmount = 0 : SpecialPayAmount = rawForm.SpecialPayAmount;
        SpecialPayAmount = Number(SpecialPayAmount);

        let DearnessAllowanceAmount = null;

        rawForm.DearnessAllowanceAmount == '' ?
            DearnessAllowanceAmount = 0 : DearnessAllowanceAmount = rawForm.DearnessAllowanceAmount;
        DearnessAllowanceAmount = Number(DearnessAllowanceAmount);

        let ConveyanceAllowanceAmount = null;

        rawForm.ConveyanceAllowanceAmount == '' ?
            ConveyanceAllowanceAmount = 0 : ConveyanceAllowanceAmount = rawForm.ConveyanceAllowanceAmount;
        ConveyanceAllowanceAmount = Number(ConveyanceAllowanceAmount);

        let HouseRentAllowanceAmount = null;

        rawForm.HouseRentAllowanceAmount == '' ?
            HouseRentAllowanceAmount = 0 : HouseRentAllowanceAmount = rawForm.HouseRentAllowanceAmount;
        HouseRentAllowanceAmount = Number(HouseRentAllowanceAmount);

        let MedicalAllowanceAmount = null;

        rawForm.MedicalAllowanceAmount == '' ?
            MedicalAllowanceAmount = 0 : MedicalAllowanceAmount = rawForm.MedicalAllowanceAmount;
        MedicalAllowanceAmount = Number(MedicalAllowanceAmount);

        let ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = null;

        rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount == '' ?
            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = 0 : ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount;
        ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = Number(ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount);

        let LeaveFairAssistanceStaffAmount = null;

        rawForm.LeaveFairAssistanceStaffAmount == '' ?
            LeaveFairAssistanceStaffAmount = 0 : LeaveFairAssistanceStaffAmount = rawForm.LeaveFairAssistanceStaffAmount;
        LeaveFairAssistanceStaffAmount = Number(LeaveFairAssistanceStaffAmount);

        let LeaveEncashmentAmount = null;

        rawForm.LeaveEncashmentAmount == '' ?
            LeaveEncashmentAmount = 0 : LeaveEncashmentAmount = rawForm.LeaveEncashmentAmount;
        LeaveEncashmentAmount = Number(LeaveEncashmentAmount);

        let AllowanceForSupportStaffAmount = null;

        rawForm.AllowanceForSupportStaffAmount == '' ?
            AllowanceForSupportStaffAmount = 0 : AllowanceForSupportStaffAmount = rawForm.AllowanceForSupportStaffAmount;
        AllowanceForSupportStaffAmount = Number(AllowanceForSupportStaffAmount);

        let HonorariumOrRewardAmount = null;

        rawForm.HonorariumOrRewardAmount == '' ?
            HonorariumOrRewardAmount = 0 : HonorariumOrRewardAmount = rawForm.HonorariumOrRewardAmount;
        HonorariumOrRewardAmount = Number(HonorariumOrRewardAmount);

        let OvertimeAllowanceAmount = null;

        rawForm.OvertimeAllowanceAmount == '' ?
            OvertimeAllowanceAmount = 0 : OvertimeAllowanceAmount = rawForm.OvertimeAllowanceAmount;
        OvertimeAllowanceAmount = Number(OvertimeAllowanceAmount);

        let BonusAmount = null;

        rawForm.BonusAmount == '' ?
            BonusAmount = 0 : BonusAmount = rawForm.BonusAmount;
        BonusAmount = Number(BonusAmount);

        let OtherAllowancesAmount = null;

        rawForm.OtherAllowancesAmount == '' ?
            OtherAllowancesAmount = 0 : OtherAllowancesAmount = rawForm.OtherAllowancesAmount;
        OtherAllowancesAmount = Number(OtherAllowancesAmount);

        let EmployersContributionProvidentFundAmount = null;

        rawForm.EmployersContributionProvidentFundAmount == '' ?
            EmployersContributionProvidentFundAmount = 0 : EmployersContributionProvidentFundAmount = rawForm.EmployersContributionProvidentFundAmount;
        EmployersContributionProvidentFundAmount = Number(EmployersContributionProvidentFundAmount);

        let RateOfInterestOfProvidentFund = null;

        rawForm.RateOfInterestOfProvidentFund == '' ?
            RateOfInterestOfProvidentFund = 0 : RateOfInterestOfProvidentFund = rawForm.RateOfInterestOfProvidentFund;
        RateOfInterestOfProvidentFund = Number(RateOfInterestOfProvidentFund);

        let InterestAccruedProvidentFundRateOfInterestAmount = null;

        rawForm.InterestAccruedProvidentFundRateOfInterestAmount == '' ?
            InterestAccruedProvidentFundRateOfInterestAmount = 0 : InterestAccruedProvidentFundRateOfInterestAmount = rawForm.InterestAccruedProvidentFundRateOfInterestAmount;
        InterestAccruedProvidentFundRateOfInterestAmount = Number(InterestAccruedProvidentFundRateOfInterestAmount);

        let EmployeePaidRentalAmount = null;

        rawForm.EmployeePaidRentalAmount == '' ?
            EmployeePaidRentalAmount = 0 : EmployeePaidRentalAmount = rawForm.EmployeePaidRentalAmount;
        EmployeePaidRentalAmount = Number(EmployeePaidRentalAmount);

        let OthersAmount = null;

        rawForm.OthersAmount == '' ?
            OthersAmount = 0 : OthersAmount = rawForm.OthersAmount;
        OthersAmount = Number(OthersAmount);

        let ArrearPayAmount = null;

        rawForm.ArrearPayAmount == '' ?
            ArrearPayAmount = 0 : ArrearPayAmount = rawForm.ArrearPayAmount;
        ArrearPayAmount = Number(ArrearPayAmount);

        let ReceivedFromGratuityFundAmount = null;

        rawForm.ReceivedFromGratuityFundAmount == '' ?
            ReceivedFromGratuityFundAmount = 0 : ReceivedFromGratuityFundAmount = rawForm.ReceivedFromGratuityFundAmount;
        ReceivedFromGratuityFundAmount = Number(ReceivedFromGratuityFundAmount);

        let WorkersProfitParticipationFundAmount = null;

        rawForm.WorkersProfitParticipationFundAmount == '' ?
            WorkersProfitParticipationFundAmount = 0 : WorkersProfitParticipationFundAmount = rawForm.WorkersProfitParticipationFundAmount;
        WorkersProfitParticipationFundAmount = Number(WorkersProfitParticipationFundAmount);

        let PensionAmount = null;

        rawForm.PensionAmount == '' ?
            PensionAmount = 0 : PensionAmount = rawForm.PensionAmount;
        PensionAmount = Number(PensionAmount);


        let ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = null;

        rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount == '' ?
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = 0 : ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
        ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = Number(ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount);

        if (isNaN(Number(rawForm.BasicPayAmount)) == true)
        {
            BasicPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.SpecialPayAmount)) == true)
        {
            SpecialPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.DearnessAllowanceAmount)) == true)
        {
            DearnessAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ConveyanceAllowanceAmount)) == true)
        {
            ConveyanceAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HouseRentAllowanceAmount)) == true)
        {
            HouseRentAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.MedicalAllowanceAmount)) == true)
        {
            MedicalAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount)) == true)
        {
            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveFairAssistanceStaffAmount)) == true)
        {
            LeaveFairAssistanceStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveEncashmentAmount)) == true)
        {
            LeaveEncashmentAmount = '0.00';
        }

        if (isNaN(Number(rawForm.AllowanceForSupportStaffAmount)) == true)
        {
            AllowanceForSupportStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HonorariumOrRewardAmount)) == true)
        {
            HonorariumOrRewardAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OvertimeAllowanceAmount)) == true)
        {
            OvertimeAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.BonusAmount)) == true)
        {
            BonusAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OtherAllowancesAmount)) == true)
        {
            OtherAllowancesAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployersContributionProvidentFundAmount)) == true)
        {
            EmployersContributionProvidentFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.RateOfInterestOfProvidentFund)) == true)
        {
            RateOfInterestOfProvidentFund = '0.00';
        }

        if (isNaN(Number(rawForm.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
        {
            InterestAccruedProvidentFundRateOfInterestAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployeePaidRentalAmount)) == true)
        {
            EmployeePaidRentalAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OthersAmount)) == true)
        {
            OthersAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ArrearPayAmount)) == true)
        {
            ArrearPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedFromGratuityFundAmount)) == true)
        {
            ReceivedFromGratuityFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.WorkersProfitParticipationFundAmount)) == true)
        {
            WorkersProfitParticipationFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.PensionAmount)) == true)
        {
            PensionAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
        {
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = '0.00';
        }

        var salaryClass = new SalaryClass(
            BasicPayAmount,
            rawForm.BasicPayExempted,
            rawForm.BasicPayTaxable,

            SpecialPayAmount,
            rawForm.SpecialPayExempted,
            rawForm.SpecialPayTaxable,

            DearnessAllowanceAmount,
            rawForm.DearnessAllowanceExempted,
            rawForm.DearnessAllowanceTaxable,

            ConveyanceAllowanceAmount,
            rawForm.ConveyanceAllowanceExempted,
            rawForm.ConveyanceAllowanceTaxable,

            HouseRentAllowanceAmount,
            rawForm.HouseRentAllowanceExempted,
            rawForm.HouseRentAllowanceTaxable,

            MedicalAllowanceAmount,
            rawForm.MedicalAllowanceExempted,
            rawForm.MedicalAllowanceTaxable,
            rawForm.IsDisabledPerson,

            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount,
            rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted,
            rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable,
            rawForm.IsShareHolder,

            LeaveFairAssistanceStaffAmount,
            rawForm.LeaveFairAssistanceStaffExempted,
            rawForm.LeaveFairAssistanceStaffTaxable,
            rawForm.IsMentionedInEmploymentContract,

            LeaveEncashmentAmount,
            rawForm.LeaveEncashmentExempted,
            rawForm.LeaveEncashmentTaxable,

            AllowanceForSupportStaffAmount,
            rawForm.AllowanceForSupportStaffExempted,
            rawForm.AllowanceForSupportStaffTaxable,

            HonorariumOrRewardAmount,
            rawForm.HonorariumOrRewardExempted,
            rawForm.HonorariumOrRewardTaxable,

            OvertimeAllowanceAmount,
            rawForm.OvertimeAllowanceExempted,
            rawForm.OvertimeAllowanceTaxable,

            BonusAmount,
            rawForm.BonusExempted,
            rawForm.BonusTaxable,

            OtherAllowancesAmount,
            rawForm.OtherAllowancesExempted,
            rawForm.OtherAllowancesTaxable,

            EmployersContributionProvidentFundAmount,
            rawForm.EmployersContributionProvidentFundExempted,
            rawForm.EmployersContributionProvidentFundTaxable,

            RateOfInterestOfProvidentFund,
            InterestAccruedProvidentFundRateOfInterestAmount,
            rawForm.InterestAccruedProvidentFundRateOfInterestExempted,
            rawForm.InterestAccruedProvidentFundRateOfInterestTaxable,

            rawForm.ReceivedTransportFromEmployerTaxable,
            rawForm.IsReceivedAnyTransportFromEmployer,

            rawForm.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer,
            rawForm.EmployerPaidRentalAmount,
            EmployeePaidRentalAmount,
            rawForm.DeemedFreeAccommodationExempted,

            OthersAmount,
            rawForm.OthersExempted,
            rawForm.OthersTaxable,

            ArrearPayAmount,
            rawForm.ArrearPayExempted,
            rawForm.ArrearPayTaxable,

            ReceivedFromGratuityFundAmount,
            rawForm.ReceivedFromGratuityFundExempted,
            rawForm.ReceivedFromGratuityFundTaxable,

            WorkersProfitParticipationFundAmount,
            rawForm.WorkersProfitParticipationFundExempted,
            rawForm.WorkersProfitParticipationFundTaxable,

            PensionAmount,
            rawForm.PensionExempted,
            rawForm.PensionTaxable,

            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable,

            rawForm.TotalAmount,
            rawForm.TotalExempted,
            rawForm.NetTaxable,
        );


        if (key == "IsShareHolder")
        {


            this.form.patchValue({


                ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted: Number(salaryClass.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted).toFixed(2),
                ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable: Number(salaryClass.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable).toFixed(2)
            })


            this.form.patchValue({


                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }

        if (key == "IsMentionedInEmploymentContract")
        {


            this.form.patchValue({


                LeaveFairAssistanceStaffExempted: Number(salaryClass.LeaveFairAssistanceStaffExempted).toFixed(2),
                LeaveFairAssistanceStaffTaxable: Number(salaryClass.LeaveFairAssistanceStaffTaxable).toFixed(2),
            })


            this.form.patchValue({


                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }

        if (key == "IsReceivedAnyTransportFromEmployer")
        {


            this.form.patchValue({

                ReceivedTransportFromEmployerTaxable: Number(salaryClass.ReceivedTransportFromEmployerTaxable).toFixed(2),

                ConveyanceAllowanceExempted: Number(salaryClass.ConveyanceAllowanceExempted).toFixed(2),

                ConveyanceAllowanceTaxable: Number(salaryClass.ConveyanceAllowanceTaxable).toFixed(2),
            })


            this.form.patchValue({

                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }
        if (key == "IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer")
        {

            this.form.patchValue({
                EmployerPaidRentalAmount: Number(salaryClass.EmployerPaidRentalAmount).toFixed(2),
                DeemedFreeAccommodationExempted: Number(salaryClass.DeemedFreeAccommodationExempted).toFixed(2),


            })


            this.form.patchValue({

                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }
    }

    onFocusoutEvent(event, key)
    {
        let rawForm = this.form.getRawValue();

        let BasicPayAmount = null;

        rawForm.BasicPayAmount == '' ?
            BasicPayAmount = 0 : BasicPayAmount = rawForm.BasicPayAmount;
        BasicPayAmount = Number(BasicPayAmount);

        let SpecialPayAmount = null;

        rawForm.SpecialPayAmount == '' ?
            SpecialPayAmount = 0 : SpecialPayAmount = rawForm.SpecialPayAmount;
        SpecialPayAmount = Number(SpecialPayAmount);

        let DearnessAllowanceAmount = null;

        rawForm.DearnessAllowanceAmount == '' ?
            DearnessAllowanceAmount = 0 : DearnessAllowanceAmount = rawForm.DearnessAllowanceAmount;
        DearnessAllowanceAmount = Number(DearnessAllowanceAmount);

        let ConveyanceAllowanceAmount = null;

        rawForm.ConveyanceAllowanceAmount == '' ?
            ConveyanceAllowanceAmount = 0 : ConveyanceAllowanceAmount = rawForm.ConveyanceAllowanceAmount;
        ConveyanceAllowanceAmount = Number(ConveyanceAllowanceAmount);

        let HouseRentAllowanceAmount = null;

        rawForm.HouseRentAllowanceAmount == '' ?
            HouseRentAllowanceAmount = 0 : HouseRentAllowanceAmount = rawForm.HouseRentAllowanceAmount;
        HouseRentAllowanceAmount = Number(HouseRentAllowanceAmount);

        let MedicalAllowanceAmount = null;

        rawForm.MedicalAllowanceAmount == '' ?
            MedicalAllowanceAmount = 0 : MedicalAllowanceAmount = rawForm.MedicalAllowanceAmount;
        MedicalAllowanceAmount = Number(MedicalAllowanceAmount);

        let ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = null;

        rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount == '' ?
            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = 0 : ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount;
        ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = Number(ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount);

        let LeaveFairAssistanceStaffAmount = null;

        rawForm.LeaveFairAssistanceStaffAmount == '' ?
            LeaveFairAssistanceStaffAmount = 0 : LeaveFairAssistanceStaffAmount = rawForm.LeaveFairAssistanceStaffAmount;
        LeaveFairAssistanceStaffAmount = Number(LeaveFairAssistanceStaffAmount);

        let LeaveEncashmentAmount = null;

        rawForm.LeaveEncashmentAmount == '' ?
            LeaveEncashmentAmount = 0 : LeaveEncashmentAmount = rawForm.LeaveEncashmentAmount;
        LeaveEncashmentAmount = Number(LeaveEncashmentAmount);

        let AllowanceForSupportStaffAmount = null;

        rawForm.AllowanceForSupportStaffAmount == '' ?
            AllowanceForSupportStaffAmount = 0 : AllowanceForSupportStaffAmount = rawForm.AllowanceForSupportStaffAmount;
        AllowanceForSupportStaffAmount = Number(AllowanceForSupportStaffAmount);

        let HonorariumOrRewardAmount = null;

        rawForm.HonorariumOrRewardAmount == '' ?
            HonorariumOrRewardAmount = 0 : HonorariumOrRewardAmount = rawForm.HonorariumOrRewardAmount;
        HonorariumOrRewardAmount = Number(HonorariumOrRewardAmount);

        let OvertimeAllowanceAmount = null;

        rawForm.OvertimeAllowanceAmount == '' ?
            OvertimeAllowanceAmount = 0 : OvertimeAllowanceAmount = rawForm.OvertimeAllowanceAmount;
        OvertimeAllowanceAmount = Number(OvertimeAllowanceAmount);

        let BonusAmount = null;

        rawForm.BonusAmount == '' ?
            BonusAmount = 0 : BonusAmount = rawForm.BonusAmount;
        BonusAmount = Number(BonusAmount);

        let OtherAllowancesAmount = null;

        rawForm.OtherAllowancesAmount == '' ?
            OtherAllowancesAmount = 0 : OtherAllowancesAmount = rawForm.OtherAllowancesAmount;
        OtherAllowancesAmount = Number(OtherAllowancesAmount);

        let EmployersContributionProvidentFundAmount = null;

        rawForm.EmployersContributionProvidentFundAmount == '' ?
            EmployersContributionProvidentFundAmount = 0 : EmployersContributionProvidentFundAmount = rawForm.EmployersContributionProvidentFundAmount;
        EmployersContributionProvidentFundAmount = Number(EmployersContributionProvidentFundAmount);

        let RateOfInterestOfProvidentFund = null;

        rawForm.RateOfInterestOfProvidentFund == '' ?
            RateOfInterestOfProvidentFund = 0 : RateOfInterestOfProvidentFund = rawForm.RateOfInterestOfProvidentFund;
        RateOfInterestOfProvidentFund = Number(RateOfInterestOfProvidentFund);

        let InterestAccruedProvidentFundRateOfInterestAmount = null;

        rawForm.InterestAccruedProvidentFundRateOfInterestAmount == '' ?
            InterestAccruedProvidentFundRateOfInterestAmount = 0 : InterestAccruedProvidentFundRateOfInterestAmount = rawForm.InterestAccruedProvidentFundRateOfInterestAmount;
        InterestAccruedProvidentFundRateOfInterestAmount = Number(InterestAccruedProvidentFundRateOfInterestAmount);

        let EmployeePaidRentalAmount = null;

        rawForm.EmployeePaidRentalAmount == '' ?
            EmployeePaidRentalAmount = 0 : EmployeePaidRentalAmount = rawForm.EmployeePaidRentalAmount;
        EmployeePaidRentalAmount = Number(EmployeePaidRentalAmount);

        let OthersAmount = null;

        rawForm.OthersAmount == '' ?
            OthersAmount = 0 : OthersAmount = rawForm.OthersAmount;
        OthersAmount = Number(OthersAmount);

        let ArrearPayAmount = null;

        rawForm.ArrearPayAmount == '' ?
            ArrearPayAmount = 0 : ArrearPayAmount = rawForm.ArrearPayAmount;
        ArrearPayAmount = Number(ArrearPayAmount);

        let ReceivedFromGratuityFundAmount = null;

        rawForm.ReceivedFromGratuityFundAmount == '' ?
            ReceivedFromGratuityFundAmount = 0 : ReceivedFromGratuityFundAmount = rawForm.ReceivedFromGratuityFundAmount;
        ReceivedFromGratuityFundAmount = Number(ReceivedFromGratuityFundAmount);

        let WorkersProfitParticipationFundAmount = null;

        rawForm.WorkersProfitParticipationFundAmount == '' ?
            WorkersProfitParticipationFundAmount = 0 : WorkersProfitParticipationFundAmount = rawForm.WorkersProfitParticipationFundAmount;
        WorkersProfitParticipationFundAmount = Number(WorkersProfitParticipationFundAmount);

        let PensionAmount = null;

        rawForm.PensionAmount == '' ?
            PensionAmount = 0 : PensionAmount = rawForm.PensionAmount;
        PensionAmount = Number(PensionAmount);


        let ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = null;

        rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount == '' ?
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = 0 : ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
        ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = Number(ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount);

        if (isNaN(Number(rawForm.BasicPayAmount)) == true)
        {
            BasicPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.SpecialPayAmount)) == true)
        {
            SpecialPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.DearnessAllowanceAmount)) == true)
        {
            DearnessAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ConveyanceAllowanceAmount)) == true)
        {
            ConveyanceAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HouseRentAllowanceAmount)) == true)
        {
            HouseRentAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.MedicalAllowanceAmount)) == true)
        {
            MedicalAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount)) == true)
        {
            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveFairAssistanceStaffAmount)) == true)
        {
            LeaveFairAssistanceStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveEncashmentAmount)) == true)
        {
            LeaveEncashmentAmount = '0.00';
        }

        if (isNaN(Number(rawForm.AllowanceForSupportStaffAmount)) == true)
        {
            AllowanceForSupportStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HonorariumOrRewardAmount)) == true)
        {
            HonorariumOrRewardAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OvertimeAllowanceAmount)) == true)
        {
            OvertimeAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.BonusAmount)) == true)
        {
            BonusAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OtherAllowancesAmount)) == true)
        {
            OtherAllowancesAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployersContributionProvidentFundAmount)) == true)
        {
            EmployersContributionProvidentFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.RateOfInterestOfProvidentFund)) == true)
        {
            RateOfInterestOfProvidentFund = '0.00';
        }

        if (isNaN(Number(rawForm.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
        {
            InterestAccruedProvidentFundRateOfInterestAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployeePaidRentalAmount)) == true)
        {
            EmployeePaidRentalAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OthersAmount)) == true)
        {
            OthersAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ArrearPayAmount)) == true)
        {
            ArrearPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedFromGratuityFundAmount)) == true)
        {
            ReceivedFromGratuityFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.WorkersProfitParticipationFundAmount)) == true)
        {
            WorkersProfitParticipationFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.PensionAmount)) == true)
        {
            PensionAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
        {
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = '0.00';
        }

        var salaryClass = new SalaryClass(
            BasicPayAmount,
            rawForm.BasicPayExempted,
            rawForm.BasicPayTaxable,

            SpecialPayAmount,
            rawForm.SpecialPayExempted,
            rawForm.SpecialPayTaxable,

            DearnessAllowanceAmount,
            rawForm.DearnessAllowanceExempted,
            rawForm.DearnessAllowanceTaxable,

            ConveyanceAllowanceAmount,
            rawForm.ConveyanceAllowanceExempted,
            rawForm.ConveyanceAllowanceTaxable,

            HouseRentAllowanceAmount,
            rawForm.HouseRentAllowanceExempted,
            rawForm.HouseRentAllowanceTaxable,

            MedicalAllowanceAmount,
            rawForm.MedicalAllowanceExempted,
            rawForm.MedicalAllowanceTaxable,
            rawForm.IsDisabledPerson,

            ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount,
            rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted,
            rawForm.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable,
            rawForm.IsShareHolder,

            LeaveFairAssistanceStaffAmount,
            rawForm.LeaveFairAssistanceStaffExempted,
            rawForm.LeaveFairAssistanceStaffTaxable,
            rawForm.IsMentionedInEmploymentContract,

            LeaveEncashmentAmount,
            rawForm.LeaveEncashmentExempted,
            rawForm.LeaveEncashmentTaxable,

            AllowanceForSupportStaffAmount,
            rawForm.AllowanceForSupportStaffExempted,
            rawForm.AllowanceForSupportStaffTaxable,

            HonorariumOrRewardAmount,
            rawForm.HonorariumOrRewardExempted,
            rawForm.HonorariumOrRewardTaxable,

            OvertimeAllowanceAmount,
            rawForm.OvertimeAllowanceExempted,
            rawForm.OvertimeAllowanceTaxable,

            BonusAmount,
            rawForm.BonusExempted,
            rawForm.BonusTaxable,

            OtherAllowancesAmount,
            rawForm.OtherAllowancesExempted,
            rawForm.OtherAllowancesTaxable,

            EmployersContributionProvidentFundAmount,
            rawForm.EmployersContributionProvidentFundExempted,
            rawForm.EmployersContributionProvidentFundTaxable,

            RateOfInterestOfProvidentFund,
            InterestAccruedProvidentFundRateOfInterestAmount,
            rawForm.InterestAccruedProvidentFundRateOfInterestExempted,
            rawForm.InterestAccruedProvidentFundRateOfInterestTaxable,

            rawForm.ReceivedTransportFromEmployerTaxable,
            rawForm.IsReceivedAnyTransportFromEmployer,

            rawForm.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer,
            rawForm.EmployerPaidRentalAmount,
            EmployeePaidRentalAmount,
            rawForm.DeemedFreeAccommodationExempted,

            OthersAmount,
            rawForm.OthersExempted,
            rawForm.OthersTaxable,

            ArrearPayAmount,
            rawForm.ArrearPayExempted,
            rawForm.ArrearPayTaxable,

            ReceivedFromGratuityFundAmount,
            rawForm.ReceivedFromGratuityFundExempted,
            rawForm.ReceivedFromGratuityFundTaxable,

            WorkersProfitParticipationFundAmount,
            rawForm.WorkersProfitParticipationFundExempted,
            rawForm.WorkersProfitParticipationFundTaxable,

            PensionAmount,
            rawForm.PensionExempted,
            rawForm.PensionTaxable,

            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable,

            rawForm.TotalAmount,
            rawForm.TotalExempted,
            rawForm.NetTaxable,
        );

        if (key == "ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount" || key == "LeaveFairAssistanceStaffAmount")
        {




            this.form.patchValue({


                ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted: Number(salaryClass.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted).toFixed(2),
                ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable: Number(salaryClass.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable).toFixed(2)
            })




            this.form.patchValue({


                LeaveFairAssistanceStaffExempted: Number(salaryClass.LeaveFairAssistanceStaffExempted).toFixed(2),
                LeaveFairAssistanceStaffTaxable: Number(salaryClass.LeaveFairAssistanceStaffTaxable).toFixed(2),
            })









            this.form.patchValue({

                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),


            })
        }
        if (key == "HouseRentAllowanceAmount" || key == "RateOfInterestOfProvidentFund" || key == "InterestAccruedProvidentFundRateOfInterestAmount" || key == "BasicPayAmount" || key == "DearnessAllowanceAmount" || key == "MedicalAllowanceAmount" || key == "EmployerPaidRentalAmount" || key == "EmployeePaidRentalAmount" || key == "SpecialPayAmount")
        {



            this.form.patchValue({


                BasicPayExempted: Number(salaryClass.BasicPayExempted).toFixed(2),
                BasicPayTaxable: Number(salaryClass.BasicPayTaxable).toFixed(2),
            });

            this.form.patchValue({


                DearnessAllowanceExempted: Number(salaryClass.DearnessAllowanceExempted).toFixed(2),
                DearnessAllowanceTaxable: Number(salaryClass.DearnessAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({


                SpecialPayExempted: Number(salaryClass.SpecialPayExempted).toFixed(2),
                SpecialPayTaxable: Number(salaryClass.SpecialPayTaxable).toFixed(2),
            })






            this.form.patchValue({


                HouseRentAllowanceExempted: Number(salaryClass.HouseRentAllowanceExempted).toFixed(2),
                HouseRentAllowanceTaxable: Number(salaryClass.HouseRentAllowanceTaxable).toFixed(2),

            });




            this.form.patchValue({


                ReceivedTransportFromEmployerTaxable: Number(salaryClass.ReceivedTransportFromEmployerTaxable).toFixed(2),
            })








            this.form.patchValue({

                MedicalAllowanceExempted: Number(salaryClass.MedicalAllowanceExempted).toFixed(2),
                MedicalAllowanceTaxable: Number(salaryClass.MedicalAllowanceTaxable).toFixed(2),
            });








            this.form.patchValue({


                InterestAccruedProvidentFundRateOfInterestExempted: Number(salaryClass.InterestAccruedProvidentFundRateOfInterestExempted).toFixed(2),
                InterestAccruedProvidentFundRateOfInterestTaxable: Number(salaryClass.InterestAccruedProvidentFundRateOfInterestTaxable).toFixed(2),
            })





            this.form.patchValue({


                EmployerPaidRentalAmount: Number(salaryClass.EmployerPaidRentalAmount).toFixed(2),
                DeemedFreeAccommodationExempted: Number(salaryClass.DeemedFreeAccommodationExempted).toFixed(2),

            });







            this.form.patchValue({

                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }
        if (key == "LeaveEncashmentAmount" || key == "AllowanceForSupportStaffAmount" || key == "HonorariumOrRewardAmount" || key == "OvertimeAllowanceAmount" || key == "BonusAmount" || key == "OtherAllowancesAmount" || key == "EmployersContributionProvidentFundAmount" || key == "OthersAmount" || key == "ArrearPayAmount")
        {
            this.form.patchValue({


                LeaveEncashmentExempted: Number(salaryClass.LeaveEncashmentExempted).toFixed(2),
                LeaveEncashmentTaxable: Number(salaryClass.LeaveEncashmentTaxable).toFixed(2),
            })

            this.form.patchValue({


                AllowanceForSupportStaffExempted: Number(salaryClass.AllowanceForSupportStaffExempted).toFixed(2),
                AllowanceForSupportStaffTaxable: Number(salaryClass.AllowanceForSupportStaffTaxable).toFixed(2),
            })

            this.form.patchValue({


                HonorariumOrRewardExempted: Number(salaryClass.HonorariumOrRewardExempted).toFixed(2),
                HonorariumOrRewardTaxable: Number(salaryClass.HonorariumOrRewardTaxable).toFixed(2),
            })

            this.form.patchValue({


                OvertimeAllowanceExempted: Number(salaryClass.OvertimeAllowanceExempted).toFixed(2),
                OvertimeAllowanceTaxable: Number(salaryClass.OvertimeAllowanceAmount).toFixed(2),
            })

            this.form.patchValue({


                BonusExempted: Number(salaryClass.BonusExempted).toFixed(2),
                BonusTaxable: Number(salaryClass.BonusTaxable).toFixed(2),
            })

            this.form.patchValue({


                OtherAllowancesExempted: Number(salaryClass.OtherAllowancesExempted).toFixed(2),
                OtherAllowancesTaxable: Number(salaryClass.OtherAllowancesTaxable).toFixed(2),
            })

            this.form.patchValue({


                EmployersContributionProvidentFundExempted: Number(salaryClass.EmployersContributionProvidentFundExempted).toFixed(2),
                EmployersContributionProvidentFundTaxable: Number(salaryClass.EmployersContributionProvidentFundTaxable).toFixed(2),
            })

            this.form.patchValue({


                OthersExempted: Number(salaryClass.OthersExempted).toFixed(2),
                OthersTaxable: Number(salaryClass.OthersTaxable).toFixed(2),
            })

            this.form.patchValue({


                ArrearPayExempted: Number(salaryClass.ArrearPayExempted).toFixed(2),
                ArrearPayTaxable: Number(salaryClass.ArrearPayTaxable).toFixed(2),
            })





            this.form.patchValue({


                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }
        if (key == "ConveyanceAllowanceAmount" || key == "ReceivedFromGratuityFundAmount" || key == "WorkersProfitParticipationFundAmount" || key == "PensionAmount" || key == "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount")
        {


            this.form.patchValue({


                ConveyanceAllowanceExempted: Number(salaryClass.ConveyanceAllowanceExempted).toFixed(2),
                ConveyanceAllowanceTaxable: Number(salaryClass.ConveyanceAllowanceTaxable).toFixed(2),
            })


            this.form.patchValue({


                ReceivedFromGratuityFundExempted: Number(salaryClass.ReceivedFromGratuityFundExempted).toFixed(2),
                ReceivedFromGratuityFundTaxable: Number(salaryClass.ReceivedFromGratuityFundTaxable).toFixed(2),
            })


            this.form.patchValue({

                WorkersProfitParticipationFundExempted: Number(salaryClass.WorkersProfitParticipationFundExempted).toFixed(2),
                WorkersProfitParticipationFundTaxable: Number(salaryClass.WorkersProfitParticipationFundTaxable).toFixed(2),
            })


            this.form.patchValue({

                PensionExempted: Number(salaryClass.PensionExempted).toFixed(2),
                PensionTaxable: Number(salaryClass.PensionTaxable).toFixed(2),
            })

            this.form.patchValue({
                ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: Number(salaryClass.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted).toFixed(2),
                ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: Number(salaryClass.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable).toFixed(2),
            })



            this.form.patchValue({

                TotalAmount: Number(salaryClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryClass.NetTaxable).toFixed(2),
            })
        }

    }

    onPasteEvent(event: ClipboardEvent, key)
    {
        // console.log(event, 'onKey ClipboardEvent');
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        // console.log("Pasted: ", pastedText);
        // console.log("key: ", key);
        let mynum = Number(pastedText) ? 'num' : 'nonum';
        // console.log("mynum: ", mynum);

    }
}

