import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { SalaryGovtService } from './Salary-govt.service';
import { SalaryGovtClass } from './SalaryGovt.Class';
import { bangla, english } from 'app/util/locale';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


@Component({
    selector: 'Salary-govt-form-control',
    templateUrl: './Salary-govt-form-control.component.html',
    styleUrls: ['./Salary-govt.component.scss']

})
export class SalaryGovtFormControlComponent
{
    @Input() control: BaseControl<string>;
    @Input() form: FormGroup;
    amountpaid: any;
    lang = '';
    receivedAnyHouse: boolean;
    DateOfBirth: any;
    IsCurrentYear: any;

    // @ViewChild('TotalExempted', { static: false }) totalExempted: ElementRef;
    constructor(
        private _salaryService: SalaryGovtService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

    )
    {
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.form.disable();
        }
    }

    onFocusoutEvent(event, key)
    {
        let rawForm = this.form.getRawValue();

        let BasicPayAmount = null;

        rawForm.BasicPayAmount == '' ?
            BasicPayAmount = 0 : BasicPayAmount = rawForm.BasicPayAmount;
        BasicPayAmount = Number(BasicPayAmount);

        let SpecialPayAmount = null;

        rawForm.SpecialPayAmount == '' ?
            SpecialPayAmount = 0 : SpecialPayAmount = rawForm.SpecialPayAmount;
        SpecialPayAmount = Number(SpecialPayAmount);

        let DearnessAllowanceAmount = null;

        rawForm.DearnessAllowanceAmount == '' ?
            DearnessAllowanceAmount = 0 : DearnessAllowanceAmount = rawForm.DearnessAllowanceAmount;
        DearnessAllowanceAmount = Number(DearnessAllowanceAmount);



        let ConveyanceAllowanceAmount = null;

        rawForm.ConveyanceAllowanceAmount == '' ?
            ConveyanceAllowanceAmount = 0 : ConveyanceAllowanceAmount = rawForm.ConveyanceAllowanceAmount;
        ConveyanceAllowanceAmount = Number(ConveyanceAllowanceAmount);


        let HouseRentAllowanceAmount = null;

        rawForm.HouseRentAllowanceAmount == '' ?
            HouseRentAllowanceAmount = 0 : HouseRentAllowanceAmount = rawForm.HouseRentAllowanceAmount;
        HouseRentAllowanceAmount = Number(HouseRentAllowanceAmount);

        let MedicalAllowanceAmount = null;

        rawForm.MedicalAllowanceAmount == '' ?
            MedicalAllowanceAmount = 0 : MedicalAllowanceAmount = rawForm.MedicalAllowanceAmount;
        MedicalAllowanceAmount = Number(MedicalAllowanceAmount);

        let LeaveFairAssistanceStaffAmount = null;

        rawForm.LeaveFairAssistanceStaffAmount == '' ?
            LeaveFairAssistanceStaffAmount = 0 : LeaveFairAssistanceStaffAmount = rawForm.LeaveFairAssistanceStaffAmount;
        LeaveFairAssistanceStaffAmount = Number(LeaveFairAssistanceStaffAmount);

        let LeaveEncashmentAmount = null;

        rawForm.LeaveEncashmentAmount == '' ?
            LeaveEncashmentAmount = 0 : LeaveEncashmentAmount = rawForm.LeaveEncashmentAmount;
        LeaveEncashmentAmount = Number(LeaveEncashmentAmount);

        let AllowanceForSupportStaffAmount = null;

        rawForm.AllowanceForSupportStaffAmount == '' ?
            AllowanceForSupportStaffAmount = 0 : AllowanceForSupportStaffAmount = rawForm.AllowanceForSupportStaffAmount;
        AllowanceForSupportStaffAmount = Number(AllowanceForSupportStaffAmount);

        let HonorariumOrRewardAmount = null;

        rawForm.HonorariumOrRewardAmount == '' ?
            HonorariumOrRewardAmount = 0 : HonorariumOrRewardAmount = rawForm.HonorariumOrRewardAmount;
        HonorariumOrRewardAmount = Number(HonorariumOrRewardAmount);

        let OvertimeAllowanceAmount = null;

        rawForm.OvertimeAllowanceAmount == '' ?
            OvertimeAllowanceAmount = 0 : OvertimeAllowanceAmount = rawForm.OvertimeAllowanceAmount;
        OvertimeAllowanceAmount = Number(OvertimeAllowanceAmount);

        let BonusAmount = null;

        rawForm.BonusAmount == '' ?
            BonusAmount = 0 : BonusAmount = rawForm.BonusAmount;
        BonusAmount = Number(BonusAmount);

        let OtherAllowancesAmount = null;

        rawForm.OtherAllowancesAmount == '' ?
            OtherAllowancesAmount = 0 : OtherAllowancesAmount = rawForm.OtherAllowancesAmount;
        OtherAllowancesAmount = Number(OtherAllowancesAmount);

        let EmployersContributionProvidentFundAmount = null;

        rawForm.EmployersContributionProvidentFundAmount == '' ?
            EmployersContributionProvidentFundAmount = 0 : EmployersContributionProvidentFundAmount = rawForm.EmployersContributionProvidentFundAmount;
        EmployersContributionProvidentFundAmount = Number(EmployersContributionProvidentFundAmount);

        let InterestAccruedProvidentFundRateOfInterestAmount = null;

        rawForm.InterestAccruedProvidentFundRateOfInterestAmount == '' ?
            InterestAccruedProvidentFundRateOfInterestAmount = 0 : InterestAccruedProvidentFundRateOfInterestAmount = rawForm.InterestAccruedProvidentFundRateOfInterestAmount;
        InterestAccruedProvidentFundRateOfInterestAmount = Number(InterestAccruedProvidentFundRateOfInterestAmount);

        let DeemedIncomeTransportAmount = null;

        rawForm.DeemedIncomeTransportAmount == '' ?
            DeemedIncomeTransportAmount = 0 : DeemedIncomeTransportAmount = rawForm.DeemedIncomeTransportAmount;
        DeemedIncomeTransportAmount = Number(DeemedIncomeTransportAmount);

        let EmployerPaidRentalAmount = null;

        rawForm.EmployerPaidRentalAmount == '' ?
            EmployerPaidRentalAmount = 0 : EmployerPaidRentalAmount = rawForm.EmployerPaidRentalAmount;
        EmployerPaidRentalAmount = Number(EmployerPaidRentalAmount);

        let OthersAmount = null;

        rawForm.OthersAmount == '' ?
            OthersAmount = 0 : OthersAmount = rawForm.OthersAmount;
        OthersAmount = Number(OthersAmount);

        let FestivalBonusAmount = null;

        rawForm.FestivalBonusAmount == '' ?
            FestivalBonusAmount = 0 : FestivalBonusAmount = rawForm.FestivalBonusAmount;
        FestivalBonusAmount = Number(FestivalBonusAmount);

        let BengaliNewYearBonusAmount = null;

        rawForm.BengaliNewYearBonusAmount == '' ?
            BengaliNewYearBonusAmount = 0 : BengaliNewYearBonusAmount = rawForm.BengaliNewYearBonusAmount;
        BengaliNewYearBonusAmount = Number(BengaliNewYearBonusAmount);

        let FestivalAllowanceAmount = null;

        rawForm.FestivalAllowanceAmount == '' ?
            FestivalAllowanceAmount = 0 : FestivalAllowanceAmount = rawForm.FestivalAllowanceAmount;
        FestivalAllowanceAmount = Number(FestivalAllowanceAmount);

        let ArrearPayAmount = null;

        rawForm.ArrearPayAmount == '' ?
            ArrearPayAmount = 0 : ArrearPayAmount = rawForm.ArrearPayAmount;
        ArrearPayAmount = Number(ArrearPayAmount);

        let PensionAmount = null;

        rawForm.PensionAmount == '' ?
            PensionAmount = 0 : PensionAmount = rawForm.PensionAmount;
        PensionAmount = Number(PensionAmount);

        let ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = null;

        rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount == '' ?
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = 0 : ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
        ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = Number(ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount);

        if (isNaN(Number(rawForm.BasicPayAmount)) == true)
        {
            BasicPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.SpecialPayAmount)) == true)
        {
            SpecialPayAmount = '0.00';
        }

        if (isNaN(Number(rawForm.DearnessAllowanceAmount)) == true)
        {
            DearnessAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ConveyanceAllowanceAmount)) == true)
        {
            ConveyanceAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HouseRentAllowanceAmount)) == true)
        {
            HouseRentAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.MedicalAllowanceAmount)) == true)
        {
            MedicalAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.AllowanceForSupportStaffAmount)) == true)
        {
            AllowanceForSupportStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveFairAssistanceStaffAmount)) == true)
        {
            LeaveFairAssistanceStaffAmount = '0.00';
        }

        if (isNaN(Number(rawForm.LeaveEncashmentAmount)) == true)
        {
            LeaveEncashmentAmount = '0.00';
        }

        if (isNaN(Number(rawForm.HonorariumOrRewardAmount)) == true)
        {
            HonorariumOrRewardAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OvertimeAllowanceAmount)) == true)
        {
            OvertimeAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.BonusAmount)) == true)
        {
            BonusAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OtherAllowancesAmount)) == true)
        {
            OtherAllowancesAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployersContributionProvidentFundAmount)) == true)
        {
            EmployersContributionProvidentFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
        {
            InterestAccruedProvidentFundRateOfInterestAmount = '0.00';
        }

        if (isNaN(Number(rawForm.DeemedIncomeTransportAmount)) == true)
        {
            DeemedIncomeTransportAmount = '0.00';
        }

        if (isNaN(Number(rawForm.EmployerPaidRentalAmount)) == true)
        {
            EmployerPaidRentalAmount = '0.00';
        }

        if (isNaN(Number(rawForm.FestivalBonusAmount)) == true)
        {
            FestivalBonusAmount = '0.00';
        }

        if (isNaN(Number(rawForm.BengaliNewYearBonusAmount)) == true)
        {
            BengaliNewYearBonusAmount = '0.00';
        }

        if (isNaN(Number(rawForm.FestivalAllowanceAmount)) == true)
        {
            FestivalAllowanceAmount = '0.00';
        }

        if (isNaN(Number(rawForm.PensionAmount)) == true)
        {
            PensionAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
        {
            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = '0.00';
        }

        if (isNaN(Number(rawForm.OthersAmount)) == true)
        {
            OthersAmount = '0.00';
        }

        if (isNaN(Number(rawForm.ArrearPayAmount)) == true)
        {
            ArrearPayAmount = '0.00';
        }

        var salaryGovtClass = new SalaryGovtClass(
            BasicPayAmount,
            rawForm.BasicPayExempted,
            rawForm.BasicPayTaxable,

            SpecialPayAmount,
            rawForm.SpecialPayExempted,
            rawForm.SpecialPayTaxable,

            DearnessAllowanceAmount,
            rawForm.DearnessAllowanceExempted,
            rawForm.DearnessAllowanceTaxable,

            ConveyanceAllowanceAmount,
            rawForm.ConveyanceAllowanceExempted,
            rawForm.ConveyanceAllowanceTaxable,

            HouseRentAllowanceAmount,
            rawForm.HouseRentAllowanceExempted,
            rawForm.HouseRentAllowanceTaxable,

            MedicalAllowanceAmount,
            rawForm.MedicalAllowanceExempted,
            rawForm.MedicalAllowanceTaxable,

            AllowanceForSupportStaffAmount,
            rawForm.AllowanceForSupportStaffExempted,
            rawForm.AllowanceForSupportStaffTaxable,

            LeaveFairAssistanceStaffAmount,
            rawForm.LeaveFairAssistanceStaffExempted,
            rawForm.LeaveFairAssistanceStaffTaxable,

            LeaveEncashmentAmount,
            rawForm.LeaveEncashmentExempted,
            rawForm.LeaveEncashmentTaxable,

            HonorariumOrRewardAmount,
            rawForm.HonorariumOrRewardExempted,
            rawForm.HonorariumOrRewardTaxable,

            OvertimeAllowanceAmount,
            rawForm.OvertimeAllowanceExempted,
            rawForm.OvertimeAllowanceTaxable,

            BonusAmount,
            rawForm.BonusExempted,
            rawForm.BonusTaxable,

            OtherAllowancesAmount,
            rawForm.OtherAllowancesExempted,
            rawForm.OtherAllowancesTaxable,

            EmployersContributionProvidentFundAmount,
            rawForm.EmployersContributionProvidentFundExempted,
            rawForm.EmployersContributionProvidentFundTaxable,

            InterestAccruedProvidentFundRateOfInterestAmount,
            rawForm.InterestAccruedProvidentFundRateOfInterestExempted,
            rawForm.InterestAccruedProvidentFundRateOfInterestTaxable,

            DeemedIncomeTransportAmount,
            rawForm.DeemedIncomeTransportExempted,
            rawForm.DeemedIncomeTransportTaxable,

            EmployerPaidRentalAmount,
            rawForm.DeemedFreeAccommodationExempted,
            rawForm.FurnishedOrUnfurnishedHouseFromEmployerTaxable,

            FestivalBonusAmount,
            rawForm.FestivalBonusExempted,
            rawForm.FestivalBonusTaxable,

            BengaliNewYearBonusAmount,
            rawForm.BengaliNewYearBonusExempted,
            rawForm.BengaliNewYearBonusTaxable,

            FestivalAllowanceAmount,
            rawForm.FestivalAllowanceExempted,
            rawForm.FestivalAllowanceTaxable,

            PensionAmount,
            rawForm.PensionExempted,
            rawForm.PensionTaxable,

            ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted,
            rawForm.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable,


            OthersAmount,
            rawForm.OthersExempted,
            rawForm.OthersTaxable,

            ArrearPayAmount,
            rawForm.ArrearPayExempted,
            rawForm.ArrearPayTaxable,

            rawForm.TotalAmount,
            rawForm.TotalExempted,
            rawForm.NetTaxable,
        )
        if (
            key == "BasicPayAmount" ||
            key == "SpecialPayAmount" ||
            key == "DearnessAllowanceAmount" ||
            key == "ConveyanceAllowanceAmount" ||
            key == "HouseRentAllowanceAmount" ||
            key == "MedicalAllowanceAmount" ||
            key == "LeaveFairAssistanceStaffAmount" ||
            key == "LeaveEncashmentAmount" ||
            key == "AllowanceForSupportStaffAmount" ||
            key == "HonorariumOrRewardAmount" ||
            key == "OvertimeAllowanceAmount" ||
            key == "BonusAmount" ||
            key == "OtherAllowancesAmount" ||
            key == "EmployersContributionProvidentFundAmount" ||
            key == "InterestAccruedProvidentFundRateOfInterestAmount" ||
            key == "DeemedIncomeTransportAmount" ||
            key == "EmployerPaidRentalAmount" ||
            key == "OthersAmount" ||
            key == "FestivalBonusAmount" ||
            key == "BengaliNewYearBonusAmount" ||
            key == "FestivalAllowanceAmount" ||
            key == "ArrearPayAmount" ||
            key == "PensionAmount" ||
            key == "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount"
        )
        {
            this.form.patchValue({
                BasicPayExempted: Number(salaryGovtClass.BasicPayExempted).toFixed(2),
                BasicPayTaxable: Number(salaryGovtClass.BasicPayTaxable).toFixed(2),
            });

            this.form.patchValue({
                SpecialPayExempted: Number(salaryGovtClass.SpecialPayExempted).toFixed(2),
                SpecialPayTaxable: Number(salaryGovtClass.SpecialPayTaxable).toFixed(2),
            })

            this.form.patchValue({
                DearnessAllowanceExempted: Number(salaryGovtClass.DearnessAllowanceExempted).toFixed(2),
                DearnessAllowanceTaxable: Number(salaryGovtClass.DearnessAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                ConveyanceAllowanceExempted: Number(salaryGovtClass.ConveyanceAllowanceExempted).toFixed(2),
                ConveyanceAllowanceTaxable: Number(salaryGovtClass.ConveyanceAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                HouseRentAllowanceExempted: Number(salaryGovtClass.HouseRentAllowanceExempted).toFixed(2),
                HouseRentAllowanceTaxable: Number(salaryGovtClass.HouseRentAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                MedicalAllowanceExempted: Number(salaryGovtClass.MedicalAllowanceExempted).toFixed(2),
                MedicalAllowanceTaxable: Number(salaryGovtClass.MedicalAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                LeaveFairAssistanceStaffExempted: Number(salaryGovtClass.LeaveFairAssistanceStaffExempted).toFixed(2),
                LeaveFairAssistanceStaffTaxable: Number(salaryGovtClass.LeaveFairAssistanceStaffTaxable).toFixed(2),
            })

            this.form.patchValue({
                LeaveEncashmentExempted: Number(salaryGovtClass.LeaveEncashmentExempted).toFixed(2),
                LeaveEncashmentTaxable: Number(salaryGovtClass.LeaveEncashmentTaxable).toFixed(2),
            })

            this.form.patchValue({
                AllowanceForSupportStaffExempted: Number(salaryGovtClass.AllowanceForSupportStaffExempted).toFixed(2),
                AllowanceForSupportStaffTaxable: Number(salaryGovtClass.AllowanceForSupportStaffTaxable).toFixed(2),
            })

            this.form.patchValue({
                HonorariumOrRewardExempted: Number(salaryGovtClass.HonorariumOrRewardExempted).toFixed(2),
                HonorariumOrRewardTaxable: Number(salaryGovtClass.HonorariumOrRewardTaxable).toFixed(2),
            })

            this.form.patchValue({
                OvertimeAllowanceExempted: Number(salaryGovtClass.OvertimeAllowanceExempted).toFixed(2),
                OvertimeAllowanceTaxable: Number(salaryGovtClass.OvertimeAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                BonusExempted: Number(salaryGovtClass.BonusExempted).toFixed(2),
                BonusTaxable: Number(salaryGovtClass.BonusTaxable).toFixed(2),
            })

            this.form.patchValue({
                OtherAllowancesExempted: Number(salaryGovtClass.OtherAllowancesExempted).toFixed(2),
                OtherAllowancesTaxable: Number(salaryGovtClass.OtherAllowancesTaxable).toFixed(2),
            })

            this.form.patchValue({
                EmployersContributionProvidentFundExempted: Number(salaryGovtClass.EmployersContributionProvidentFundExempted).toFixed(2),
                EmployersContributionProvidentFundTaxable: Number(salaryGovtClass.EmployersContributionProvidentFundTaxable).toFixed(2),
            })

            this.form.patchValue({
                InterestAccruedProvidentFundRateOfInterestExempted: Number(salaryGovtClass.InterestAccruedProvidentFundRateOfInterestExempted).toFixed(2),
                InterestAccruedProvidentFundRateOfInterestTaxable: Number(salaryGovtClass.InterestAccruedProvidentFundRateOfInterestTaxable).toFixed(2),
            })

            this.form.patchValue({
                DeemedIncomeTransportExempted: Number(salaryGovtClass.DeemedIncomeTransportExempted).toFixed(2),
                DeemedIncomeTransportTaxable: Number(salaryGovtClass.DeemedIncomeTransportTaxable).toFixed(2),
            })

            this.form.patchValue({
                DeemedFreeAccommodationExempted: Number(salaryGovtClass.DeemedFreeAccommodationExempted).toFixed(2),
                FurnishedOrUnfurnishedHouseFromEmployerTaxable: Number(salaryGovtClass.FurnishedOrUnfurnishedHouseFromEmployerTaxable).toFixed(2),
            })

            this.form.patchValue({
                OthersExempted: Number(salaryGovtClass.OthersExempted).toFixed(2),
                OthersTaxable: Number(salaryGovtClass.OthersTaxable).toFixed(2),
            })

            this.form.patchValue({
                FestivalBonusExempted: Number(salaryGovtClass.FestivalBonusExempted).toFixed(2),
                FestivalBonusTaxable: Number(salaryGovtClass.FestivalBonusTaxable).toFixed(2),
            })

            this.form.patchValue({
                BengaliNewYearBonusExempted: Number(salaryGovtClass.BengaliNewYearBonusExempted).toFixed(2),
                BengaliNewYearBonusTaxable: Number(salaryGovtClass.BengaliNewYearBonusTaxable).toFixed(2),
            })

            this.form.patchValue({
                FestivalAllowanceExempted: Number(salaryGovtClass.FestivalAllowanceExempted).toFixed(2),
                FestivalAllowanceTaxable: Number(salaryGovtClass.FestivalAllowanceTaxable).toFixed(2),
            })

            this.form.patchValue({
                ArrearPayExempted: Number(salaryGovtClass.ArrearPayExempted).toFixed(2),
                ArrearPayTaxable: Number(salaryGovtClass.ArrearPayTaxable).toFixed(2),
            })

            this.form.patchValue({
                PensionExempted: Number(salaryGovtClass.PensionExempted).toFixed(2),
                PensionTaxable: Number(salaryGovtClass.PensionTaxable).toFixed(2),
            })

            this.form.patchValue({
                ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: Number(salaryGovtClass.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted).toFixed(2),
                ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: Number(salaryGovtClass.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable).toFixed(2),
            })

            this.form.patchValue({
                TotalAmount: Number(salaryGovtClass.TotalAmount).toFixed(2),
                TotalExempted: Number(salaryGovtClass.TotalExempted).toFixed(2),
                NetTaxable: Number(salaryGovtClass.NetTaxable).toFixed(2),
            })
        }
    }
}

