<div [formGroup]="form">

  <div fxLayout="column"
    fxLayoutAlign="start">

    <!-- <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-bottom: 10px; padding-top: 10px; font-weight: bold;"
      fxFlex="1 0 auto">
      <div style="border: 2px solid; border-radius: 40px; padding-left: 5px;padding-right: 5px;">1</div>&nbsp; Add
      your salary income here
    </div> -->

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">
      <div fxFlex="40"
        style="margin-right: 8px !important; "
        fxLayoutAlign="center center">
        <div style="font-weight: bold;color: #444444;">{{ 'SALARY.PAY_ALLOWANCE' | translate }}</div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.YEARLY_INCOME' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.Exempted_Income' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.TAXABLE_INCOME' | translate }}
        </div>
      </div>
    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Basic_Pay' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Basic_Pay_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="BasicPayAmount"
          (keyup)="onFocusoutEvent($event,'BasicPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
        <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BasicPayExempted"
          (keyup)="onFocusoutEvent($event,'BasicPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BasicPayTaxable"
          (keyup)="onFocusoutEvent($event,'BasicPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Special_Pay' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Special_Pay_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="SpecialPayAmount"
          (keyup)="onFocusoutEvent($event,'SpecialPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="SpecialPayExempted"
          (keyup)="onFocusoutEvent($event,'SpecialPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="SpecialPayTaxable"
          (keyup)="onFocusoutEvent($event,'SpecialPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Dearness_Allowance' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Dearness_Allowance_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="DearnessAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DearnessAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DearnessAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Conveyance_Allowance' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Conveyance_Allowance_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="ConveyanceAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ConveyanceAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ConveyanceAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.H_R_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.H_R_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="HouseRentAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HouseRentAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HouseRentAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.M_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.M_A_IC' | translate }}"
          matSuffix>info</mat-icon> <br>
        <div style="color: silver;
        pointer-events: none !important;">{{ 'SALARY.R2' | translate }}
          <mat-checkbox id="IsDisabledPerson"
            [disabled]="true"
            tabindex="-1"
            style="padding-left: 5px;"
            formControlName="IsDisabledPerson"></mat-checkbox>
        </div>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="MedicalAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="MedicalAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="MedicalAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.A_S_H_L' | translate }}
        <div fxLayoutAlign="start center"
          fxLayout="row"
          style="margin-top: 5px;">
          <span class="name">{{ 'SALARY.I_S_D' | translate }}</span>
          <mat-checkbox id="IsShareHolder"
            (change)="checkChange($event,'IsShareHolder')"
            style="padding-left: 5px;"
            tabindex=""
            formControlName="IsShareHolder"></mat-checkbox>
        </div>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount"
          (keyup)="onFocusoutEvent($event,'ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted"
          (keyup)="onFocusoutEvent($event,'ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable"
          (keyup)="onFocusoutEvent($event,'ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.L_F_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.L_F_A_IC' | translate }}"
          matSuffix>info</mat-icon>
        <div fxLayoutAlign="start center"
          fxLayout="row"
          style="margin-top: 5px;">
          <span class="name"> {{ 'SALARY.L_F_A_C' | translate }}</span>
          <mat-checkbox id="IsMentionedInEmploymentContract"
            (change)="checkChange($event,'IsMentionedInEmploymentContract')"
            style="padding-left: 5px;"
            tabindex=""
            formControlName="IsMentionedInEmploymentContract"></mat-checkbox>
        </div>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="LeaveFairAssistanceStaffAmount"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveFairAssistanceStaffExempted"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveFairAssistanceStaffTaxable"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.L_E' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.L_E_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="LeaveEncashmentAmount"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveEncashmentExempted"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveEncashmentTaxable"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.A_S_S' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.A_S_S_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="AllowanceForSupportStaffAmount"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="AllowanceForSupportStaffExempted"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="AllowanceForSupportStaffTaxable"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.HON' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.HON_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="HonorariumOrRewardAmount"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HonorariumOrRewardExempted"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HonorariumOrRewardTaxable"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OV_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OV_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="OvertimeAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OvertimeAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OvertimeAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.BON' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.BON_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="BonusAmount"
          (keyup)="onFocusoutEvent($event,'BonusAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BonusExempted"
          (keyup)="onFocusoutEvent($event,'BonusExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BonusTaxable"
          (keyup)="onFocusoutEvent($event,'BonusTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OTH_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OTH_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="OtherAllowancesAmount"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OtherAllowancesExempted"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OtherAllowancesTaxable"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.E_C_P_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.E_C_P_F_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="EmployersContributionProvidentFundAmount"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="EmployersContributionProvidentFundExempted"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="EmployersContributionProvidentFundTaxable"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_A_P_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_A_P_F_IC' | translate }}"
          matSuffix>info</mat-icon>
        <div fxLayoutAlign="start center"
          fxLayout="row"
          style="margin-top: 5px;">
          <span class="name">{{ 'SALARY.R_O_I' | translate }}</span>
          <!-- <mat-form-field appearance="outline"
            fxFlex="50"
            style="margin-left: 5px !important;"> -->
          <div
            style="align-self: center;border: .9px solid;border-radius: 5px;height: 30px;width: 60px;margin-left: 5px;">
            <input NumbersOnly
              style="padding: 5px"
              type="text"
              decimals="100"
              negative="1"
              tabindex=""
              formControlName="RateOfInterestOfProvidentFund"
              (keyup)="onFocusoutEvent($event,'RateOfInterestOfProvidentFund')"
              (click)="$event.target.select()"
              class="rightA"
              autocomplete="off"
              matInput>
            <!-- </mat-form-field> -->
          </div>
        </div>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="InterestAccruedProvidentFundRateOfInterestAmount"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestAccruedProvidentFundRateOfInterestExempted"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestAccruedProvidentFundRateOfInterestTaxable"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        fxLayoutAlign="start center"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.TR_E' | translate }}
        <mat-checkbox id="IsReceivedAnyTransportFromEmployer"
          (change)="checkChange($event,'IsReceivedAnyTransportFromEmployer')"
          style="padding-left: 5px;"
          tabindex=""
          formControlName="IsReceivedAnyTransportFromEmployer"></mat-checkbox>
      </div>

      <div appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">

      </div>

      <div appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedTransportFromEmployerTaxable"
          (keyup)="onFocusoutEvent($event,'ReceivedTransportFromEmployerTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      fxFlex="1 0"
      style="margin-top: 5px;">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">

      </div>

      <div fxFlex="20"
        style="text-align: center; margin-right: 8px !important;">
        <!-- {{ 'SALARY.R4' | translate }} -->
      </div>
      <div fxFlex="20"
        style="text-align: center; margin-right: 8px !important;">
        {{ 'SALARY.R5' | translate }}
      </div>
      <div fxFlex="20">
      </div>
    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.F_U_H_E' | translate }}
        <mat-checkbox id="IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer"
          (change)="checkChange($event,'IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer')"
          style="padding-left: 5px;"
          tabindex=""
          formControlName="IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer"></mat-checkbox>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="-1"
          tabindex=""
          formControlName="EmployerPaidRentalAmount"
          (keyup)="onFocusoutEvent($event,'EmployerPaidRentalAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="EmployeePaidRentalAmount"
          (keyup)="onFocusoutEvent($event,'EmployeePaidRentalAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DeemedFreeAccommodationExempted"
          (keyup)="onFocusoutEvent($event,'DeemedFreeAccommodationExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OTH' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OTH_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="OthersAmount"
          (keyup)="onFocusoutEvent($event,'OthersAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OthersExempted"
          (keyup)="onFocusoutEvent($event,'OthersExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OthersTaxable"
          (keyup)="onFocusoutEvent($event,'OthersTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.ARR_P' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.ARR_P_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="ArrearPayAmount"
          (keyup)="onFocusoutEvent($event,'ArrearPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ArrearPayExempted"
          (keyup)="onFocusoutEvent($event,'ArrearPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ArrearPayTaxable"
          (keyup)="onFocusoutEvent($event,'ArrearPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_G_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_G_F_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="ReceivedFromGratuityFundAmount"
          (keyup)="onFocusoutEvent($event,'ReceivedFromGratuityFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromGratuityFundExempted"
          (keyup)="onFocusoutEvent($event,'ReceivedFromGratuityFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromGratuityFundTaxable"
          (keyup)="onFocusoutEvent($event,'ReceivedFromGratuityFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_W_P_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_W_P_F_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="WorkersProfitParticipationFundAmount"
          (keyup)="onFocusoutEvent($event,'WorkersProfitParticipationFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="WorkersProfitParticipationFundExempted"
          (keyup)="onFocusoutEvent($event,'WorkersProfitParticipationFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="WorkersProfitParticipationFundTaxable"
          (keyup)="onFocusoutEvent($event,'WorkersProfitParticipationFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.PEN' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.PEN_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="PensionAmount"
          (keyup)="onFocusoutEvent($event,'PensionAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="PensionExempted"
          (keyup)="onFocusoutEvent($event,'PensionExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="PensionTaxable"
          (keyup)="onFocusoutEvent($event,'PensionTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_P_S_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_P_S_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 3px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important; font-weight: bold;">
        {{ 'SALARY.TAXABLE_INCOME' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalAmount"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalExempted"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="NetTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-bottom: 10px; padding-top: 20px; font-weight: bold;"
      fxFlex="1 0 auto">
      <!-- <div style="border: 2px solid; border-radius: 40px; padding-left: 5px;padding-right: 5px;">2</div> -->
      {{ 'SALARY.R3' | translate }}
    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Organization_Name' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T1' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">
        <input type="text"
          formControlName="OrganizationName"
          class="leftA"
          autocomplete="off"
          matInput>
        <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Organization_Address' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T2' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">

        <textarea style="resize:vertical"
          rows="2"
          type="text"
          formControlName="OrganizationAddress"
          class="leftA"
          autocomplete="off"
          matInput>
      </textarea>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Buisness_Identification_No' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T3' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">
        <input formControlName="BIN"
          maxlength="9"
          minlength="9"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          class="leftA"
          autocomplete="off"
          matInput>
        <!-- <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error> -->
      </mat-form-field>

    </div>
  </div>
</div>
