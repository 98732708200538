import * as Lang_E from './Lang_E.json';
import * as Lang_B from './Lang_B.json';

export const english = {
    lang: "en",
    data: {
        COMMON: {
            BUTTON_SAVE_NEXT: Lang_E.COMMON.BUTTON_SAVE_NEXT,
            BUTTON_SAVE_EXIT: Lang_E.COMMON.BUTTON_SAVE_EXIT,
            BUTTON_SAVE: Lang_E.COMMON.BUTTON_SAVE,
            BUTTON_ADD: Lang_E.COMMON.BUTTON_ADD,
            BUTTON_UPDATE: Lang_E.COMMON.BUTTON_UPDATE,
            BUTTON_SAVE_ADD_MORE: Lang_E.COMMON.BUTTON_SAVE_ADD_MORE,
            BUTTON_ADD_MORE: Lang_E.COMMON.BUTTON_ADD_MORE,
            BUTTON_ADD_MORE_ROWS: Lang_E.COMMON.BUTTON_ADD_MORE_ROWS,
            BUTTON_EDIT: Lang_E.COMMON.BUTTON_EDIT,
            BUTTON_DELETE: Lang_E.COMMON.BUTTON_DELETE,
            BUTTON_DELETED: Lang_E.COMMON.BUTTON_DELETED,
            BUTTON_NEXT: Lang_E.COMMON.BUTTON_NEXT,
            BUTTON_NEXT_PAGE: Lang_E.COMMON.BUTTON_NEXT_PAGE,
            BUTTON_PREVIOUS: Lang_E.COMMON.BUTTON_PREVIOUS,
            BUTTON_PREVIOUS_PAGE: Lang_E.COMMON.BUTTON_PREVIOUS_PAGE,
            BUTTON_AMOUNT: Lang_E.COMMON.BUTTON_AMOUNT,
            BUTTON_COMMENT: Lang_E.COMMON.BUTTON_COMMENT,
            BUTTON_DESC: Lang_E.COMMON.BUTTON_DESC,
            BUTTON_REQ: Lang_E.COMMON.BUTTON_REQ,
            NOTE_DESC: Lang_E.COMMON.NOTE_DESC,
            BUTTON_ROW: Lang_E.COMMON.BUTTON_ROW,
            BUTTON_DATA: Lang_E.COMMON.BUTTON_DATA,
            BUTTON_OK: Lang_E.COMMON.BUTTON_OK,
            BUTTON_WRONG: Lang_E.COMMON.BUTTON_WRONG,
            BUTTON_UPDATED: Lang_E.COMMON.BUTTON_UPDATED,
            BUTTON_ENTER_AMOUNT: Lang_E.COMMON.BUTTON_ENTER_AMOUNT,
            BUTTON_ENTER_DESC: Lang_E.COMMON.BUTTON_ENTER_DESC,
            BUTTON_INTEREST_AMOUNT: Lang_E.COMMON.BUTTON_INTEREST_AMOUNT,
            BUTTON_ALL: Lang_E.COMMON.BUTTON_ALL,
            BUTTON_TYPE: Lang_E.COMMON.BUTTON_TYPE,
            BUTTON_DL: Lang_E.COMMON.BUTTON_DL,
            BUTTON_CONFIRM: Lang_E.COMMON.BUTTON_CONFIRM,
            BUTTON_CANCEL: Lang_E.COMMON.BUTTON_CANCEL,
            LABEL_DELETE: Lang_E.COMMON.LABEL_DELETE,
            LABEL_NOT_SAVED: Lang_E.COMMON.LABEL_NOT_SAVED,
            NAV_HOME: Lang_E.COMMON.NAV_HOME,
            NAV_HOW: Lang_E.COMMON.NAV_HOW,
            NAV_ABOUT: Lang_E.COMMON.NAV_ABOUT,
            NAV_PRICE: Lang_E.COMMON.NAV_PRICE,
            NAV_HELP: Lang_E.COMMON.NAV_HELP,
            NAV_BLOG: Lang_E.COMMON.NAV_BLOG,
            NAV_NEWS: Lang_E.COMMON.NAV_NEWS,
            NAV_CONTACT: Lang_E.COMMON.NAV_CONTACT,
            NAV_TC: Lang_E.COMMON.NAV_TC,
            NAV_PP: Lang_E.COMMON.NAV_PP,
            NAV_RRP: Lang_E.COMMON.NAV_RRP,
            FOOLTER_CI: Lang_E.COMMON.FOOLTER_CI,
            FOOLTER_WORK: Lang_E.COMMON.FOOLTER_WORK,
            FOOLTER_QUE: Lang_E.COMMON.FOOLTER_QUE,
            FOOLTER_SN: Lang_E.COMMON.FOOLTER_SN,
            FOOLTER_QL: Lang_E.COMMON.FOOLTER_QL,
            ITEM_1: Lang_E.COMMON.ITEM_1,
            ITEM_2: Lang_E.COMMON.ITEM_2,
            ITEM_3: Lang_E.COMMON.ITEM_3,
            ITEM_4: Lang_E.COMMON.ITEM_4,
            AUTO_GENERATED: Lang_E.COMMON.AUTO_GENERATED,
            ETIN: Lang_E.COMMON.ETIN,
            TAX_OWED: Lang_E.COMMON.TAX_OWED,
            TAX_YEAR: Lang_E.COMMON.TAX_YEAR,
            SAVE_EXIT: Lang_E.COMMON.SAVE_EXIT,
            CONFIRM_ARCHIVE: Lang_E.COMMON.CONFIRM_ARCHIVE,
            ENTER_ASSET: Lang_E.COMMON.ENTER_ASSET,

        },
        ETIN: {
            HEADER: Lang_E.ETIN.HEADER,
            EXTRA1: Lang_E.ETIN.EXTRA1,
            EXTRA2: Lang_E.ETIN.EXTRA2,
            EXTRA3: Lang_E.ETIN.EXTRA3,
            EXTRA4: Lang_E.ETIN.EXTRA4,
            R_AREA_OF_RESIDENT: Lang_E.ETIN.R_AREA_OF_RESIDENT,
            R_ETIN_12C: Lang_E.ETIN.R_ETIN_12C,
            R_CIRCLE: Lang_E.ETIN.R_CIRCLE,
            R_ZONE: Lang_E.ETIN.R_ZONE,
            R_DISABLED_CHILDREN: Lang_E.ETIN.R_DISABLED_CHILDREN,
            R_NAT_STATUS: Lang_E.ETIN.R_NAT_STATUS,
            R_RES_STATUS: Lang_E.ETIN.R_RES_STATUS,
            R_ETIN_OTHER: Lang_E.ETIN.R_ETIN_OTHER,
            R_DISABILITY_CHANGE: Lang_E.ETIN.R_DISABILITY_CHANGE,
            R_DISABILITY_CHANGE_EXIT: Lang_E.ETIN.R_DISABILITY_CHANGE_EXIT,

        },
        PERSONAL: {
            HEADER: Lang_E.PERSONAL.HEADER,
            R_ENTER_NAME: Lang_E.PERSONAL.R_ENTER_NAME,
            R_SELECT_GENDER: Lang_E.PERSONAL.R_SELECT_GENDER,
            R_DOB: Lang_E.PERSONAL.R_DOB,
            R_MARITAL_STATUS: Lang_E.PERSONAL.R_MARITAL_STATUS,
            R_MOBILE: Lang_E.PERSONAL.R_MOBILE,
            R_PRESENT_ADDRESS: Lang_E.PERSONAL.R_PRESENT_ADDRESS,
            R_PRESENT_DISTRICT: Lang_E.PERSONAL.R_PRESENT_DISTRICT,
            R_PRESENT_POSTAL: Lang_E.PERSONAL.R_PRESENT_POSTAL,
            R_PERMANENT_ADDRESS: Lang_E.PERSONAL.R_PERMANENT_ADDRESS,
            R_SELECT_DISTRICT: Lang_E.PERSONAL.R_SELECT_DISTRICT,
            R_ENTER_POSTAL: Lang_E.PERSONAL.R_ENTER_POSTAL,
            R_ENTER_NID: Lang_E.PERSONAL.R_ENTER_NID,
            R_ENTER_NID_L: Lang_E.PERSONAL.R_ENTER_NID_L,
            R_SPOUSE_NAME: Lang_E.PERSONAL.R_SPOUSE_NAME,
            R_SPOUSE_ETIN: Lang_E.PERSONAL.R_SPOUSE_ETIN,
            R_ENTER_PERSONAL_INFO: Lang_E.PERSONAL.R_ENTER_PERSONAL_INFO,
            T_PERMANENT_ADDRESS: Lang_E.PERSONAL.T_PERMANENT_ADDRESS,
            R_GENDER_CHANGE: Lang_E.PERSONAL.R_GENDER_CHANGE,
            R_GENDER_CHANGE_EXIT: Lang_E.PERSONAL.R_GENDER_CHANGE_EXIT,

        },
        PERSONALIZE: {
            HEADER: Lang_E.PERSONALIZE.HEADER,
            HEADER_2: Lang_E.PERSONALIZE.HEADER_2,
            R_MAIN_SOURCE: Lang_E.PERSONALIZE.R_MAIN_SOURCE,
            R_MAIN_SOURCE_CHANGE: Lang_E.PERSONALIZE.R_MAIN_SOURCE_CHANGE,
            R_INCOME_ASSET_CHANGE: Lang_E.PERSONALIZE.R_INCOME_ASSET_CHANGE,
            R_INCOME_ASSET_CHANGE_EXIT: Lang_E.PERSONALIZE.R_INCOME_ASSET_CHANGE_EXIT,
            R_OGR_NAME: Lang_E.PERSONALIZE.R_OGR_NAME,
            R_OGR_ADDRESS: Lang_E.PERSONALIZE.R_OGR_ADDRESS,
            R_BIN: Lang_E.PERSONALIZE.R_BIN,
            R_PER_RETURN: Lang_E.PERSONALIZE.R_PER_RETURN,
            R_ACQ_ASSETS: Lang_E.PERSONALIZE.R_ACQ_ASSETS,
            ENTER_ASSET_EXPENSE: Lang_E.PERSONALIZE.ENTER_ASSET_EXPENSE,
        },
        SPOUSECHILD: {
            HEADER: Lang_E.SPOUSECHILD.HEADER,
            R1: Lang_E.SPOUSECHILD.R1,
            R2: Lang_E.SPOUSECHILD.R2,
        },
        SALARY: {
            HEADER: Lang_E.SALARY.HEADER,
            R1: Lang_E.SALARY.R1,
            R2: Lang_E.SALARY.R2,
            R3: Lang_E.SALARY.R3,
            R4: Lang_E.SALARY.R4,
            R5: Lang_E.SALARY.R5,
            R6: Lang_E.SALARY.R6,
            R7: Lang_E.SALARY.R7,
            T1: Lang_E.SALARY.T1,
            T2: Lang_E.SALARY.T2,
            T3: Lang_E.SALARY.T3,
            GJH: Lang_E.SALARY.GJH,
            PJH: Lang_E.SALARY.PJH,
            PAY_ALLOWANCE: Lang_E.SALARY.PAY_ALLOWANCE,
            YEARLY_INCOME: Lang_E.SALARY.YEARLY_INCOME,
            Exempted_Income: Lang_E.SALARY.Exempted_Income,
            TAXABLE_INCOME: Lang_E.SALARY.TAXABLE_INCOME,
            Basic_Pay: Lang_E.SALARY.Basic_Pay,
            Basic_Pay_IC: Lang_E.SALARY.Basic_Pay_IC,
            Special_Pay: Lang_E.SALARY.Special_Pay,
            Special_Pay_IC: Lang_E.SALARY.Special_Pay_IC,
            Dearness_Allowance: Lang_E.SALARY.Dearness_Allowance,
            Dearness_Allowance_IC: Lang_E.SALARY.Dearness_Allowance_IC,
            Conveyance_Allowance: Lang_E.SALARY.Conveyance_Allowance,
            Conveyance_Allowance_IC: Lang_E.SALARY.Conveyance_Allowance_IC,
            H_R_A: Lang_E.SALARY.H_R_A,
            H_R_A_IC: Lang_E.SALARY.H_R_A_IC,
            M_A: Lang_E.SALARY.M_A,
            M_A_IC: Lang_E.SALARY.M_A_IC,
            A_S_H_L: Lang_E.SALARY.A_S_H_L,
            I_S_D: Lang_E.SALARY.I_S_D,
            L_F_A: Lang_E.SALARY.L_F_A,
            L_F_A_IC: Lang_E.SALARY.L_F_A_IC,
            L_F_A_C: Lang_E.SALARY.L_F_A_C,
            L_E: Lang_E.SALARY.L_E,
            L_E_IC: Lang_E.SALARY.L_E_IC,
            A_S_S: Lang_E.SALARY.A_S_S,
            A_S_S_IC: Lang_E.SALARY.A_S_S_IC,
            HON: Lang_E.SALARY.HON,
            HON_IC: Lang_E.SALARY.HON_IC,
            OV_A: Lang_E.SALARY.OV_A,
            OV_A_IC: Lang_E.SALARY.OV_A_IC,
            BON: Lang_E.SALARY.BON,
            BON_IC: Lang_E.SALARY.BON_IC,
            OTH_A: Lang_E.SALARY.OTH_A,
            OTH_A_IC: Lang_E.SALARY.OTH_A_IC,
            E_C_P_F: Lang_E.SALARY.E_C_P_F,
            E_C_P_F_IC: Lang_E.SALARY.E_C_P_F_IC,
            I_A_P_F: Lang_E.SALARY.I_A_P_F,
            I_A_P_F_IC: Lang_E.SALARY.I_A_P_F_IC,
            R_O_I: Lang_E.SALARY.R_O_I,
            TR_E: Lang_E.SALARY.TR_E,
            F_U_H_E: Lang_E.SALARY.F_U_H_E,
            OTH: Lang_E.SALARY.OTH,
            OTH_IC: Lang_E.SALARY.OTH_IC,
            ARR_P: Lang_E.SALARY.ARR_P,
            ARR_P_IC: Lang_E.SALARY.ARR_P_IC,
            I_G_F: Lang_E.SALARY.I_G_F,
            I_G_F_IC: Lang_E.SALARY.I_G_F_IC,
            I_W_P_F: Lang_E.SALARY.I_W_P_F,
            I_W_P_F_IC: Lang_E.SALARY.I_W_P_F_IC,
            PEN: Lang_E.SALARY.PEN,
            PEN_IC: Lang_E.SALARY.PEN_IC,
            I_P_S_A: Lang_E.SALARY.I_P_S_A,
            I_P_S_A_IC: Lang_E.SALARY.I_P_S_A_IC,
            Organization_Name: Lang_E.SALARY.Organization_Name,
            Organization_Address: Lang_E.SALARY.Organization_Address,
            Buisness_Identification_No: Lang_E.SALARY.Buisness_Identification_No,
            Deemed_Income_Transport: Lang_E.SALARY.Deemed_Income_Transport,
            Deemed_Free_Accommodation: Lang_E.SALARY.Deemed_Free_Accommodation,
            Festival_Bonus: Lang_E.SALARY.Festival_Bonus,
            Bengali_NewYear_Bonus: Lang_E.SALARY.Bengali_NewYear_Bonus,
            Festival_Allowance: Lang_E.SALARY.Festival_Allowance,
            SALARY_TYPE: Lang_E.SALARY.SALARY_TYPE,
            SALARY_TYPE_R_GOV: Lang_E.SALARY.SALARY_TYPE_R_GOV,
            SALARY_TYPE_R_PRI: Lang_E.SALARY.SALARY_TYPE_R_PRI,
        },
        SECURITIES: {
            HEADER: Lang_E.SECURITIES.HEADER,
        },
        PROPERTY: {
            HEADER: Lang_E.PROPERTY.HEADER,
            R1: Lang_E.PROPERTY.R1,
            R2: Lang_E.PROPERTY.R2,
            R3: Lang_E.PROPERTY.R3,
        },
        AGRICULTURE: {
            HEADER: Lang_E.AGRICULTURE.HEADER,
            R1: Lang_E.AGRICULTURE.R1,
            R2: Lang_E.AGRICULTURE.R2,
        },
        BUSINESS: {
            HEADER: Lang_E.BUSINESS.HEADER,
            R1: Lang_E.BUSINESS.R1,
            R2: Lang_E.BUSINESS.R2,
            R3: Lang_E.BUSINESS.R3,
        },
        SHAREPROFIT: {
            HEADER: Lang_E.SHAREPROFIT.HEADER,
            ENTER_NAME: Lang_E.SHAREPROFIT.ENTER_NAME,
            ENTER_INCOME: Lang_E.SHAREPROFIT.ENTER_INCOME,
            ENTER_OWNERSSHIP: Lang_E.SHAREPROFIT.ENTER_OWNERSSHIP,
        },
        CAPITALGAINS: {
            HEADER: Lang_E.CAPITALGAINS.HEADER,
            R1: Lang_E.CAPITALGAINS.R1,
            R2: Lang_E.CAPITALGAINS.R2,
            R3: Lang_E.CAPITALGAINS.R3,
        },
        OTHERSOURCES: {
            HEADER: Lang_E.OTHERSOURCES.HEADER,
            HEADER_1: Lang_E.OTHERSOURCES.HEADER_1,
            HEADER_2: Lang_E.OTHERSOURCES.HEADER_2,
            HEADER_3: Lang_E.OTHERSOURCES.HEADER_3,
            HEADER_4: Lang_E.OTHERSOURCES.HEADER_4,
            InterestMutualOrUnitFund: Lang_E.OTHERSOURCES.InterestMutualOrUnitFund,
            CashDividendStockExchange: Lang_E.OTHERSOURCES.CashDividendStockExchange,
            InterestIncomeWEDB: Lang_E.OTHERSOURCES.InterestIncomeWEDB,
            USDollarPremiumInvestmentBond: Lang_E.OTHERSOURCES.USDollarPremiumInvestmentBond,
            PoundSterlingPremiumInvestmentBond: Lang_E.OTHERSOURCES.PoundSterlingPremiumInvestmentBond,
            EuroPremiumInvestmentBond: Lang_E.OTHERSOURCES.EuroPremiumInvestmentBond,
            ZeroCouponBond: Lang_E.OTHERSOURCES.ZeroCouponBond,
            InvestmentPensionerSavingsInstrument: Lang_E.OTHERSOURCES.InvestmentPensionerSavingsInstrument,
            InterestReceivedPensionerSavingsInstrument: Lang_E.OTHERSOURCES.InterestReceivedPensionerSavingsInstrument,
            SumReceivedBecauseOfPositionLikeDirectorFee: Lang_E.OTHERSOURCES.SumReceivedBecauseOfPositionLikeDirectorFee,
            HonoriumWritingArticle: Lang_E.OTHERSOURCES.HonoriumWritingArticle,
            RemunerationExaminerInvigilator: Lang_E.OTHERSOURCES.RemunerationExaminerInvigilator,
            IncomeTuition: Lang_E.OTHERSOURCES.IncomeTuition,
            IncomeTelevisionRadioParticipatingProgram: Lang_E.OTHERSOURCES.IncomeTelevisionRadioParticipatingProgram,
            Others: Lang_E.OTHERSOURCES.Others,
            Total: Lang_E.OTHERSOURCES.Total,
        },
        FOREIGNSOURCES: {
            HEADER: Lang_E.FOREIGNSOURCES.HEADER,
            R1: Lang_E.FOREIGNSOURCES.R1,
        },
        REBATE: {
            HEADER: Lang_E.REBATE.HEADER,
        },
        TDS: {
            HEADER: Lang_E.TDS.HEADER,
        },
        ADVANCE: {
            HEADER: Lang_E.ADVANCE.HEADER,
        },
        ADJUSTMENT: {
            HEADER: Lang_E.ADJUSTMENT.HEADER,
            R1: Lang_E.ADJUSTMENT.R1,
        },
        CAPITAL: {
            HEADER: Lang_E.CAPITAL.HEADER,
        },
        DS: {
            HEADER: Lang_E.DS.HEADER,
            R1: Lang_E.DS.R1,
            R2: Lang_E.DS.R2,
        },
        NONAGRI: {
            HEADER: Lang_E.NONAGRI.HEADER,
            R1: Lang_E.NONAGRI.R1,
            R2: Lang_E.NONAGRI.R2,
            R3: Lang_E.NONAGRI.R3,
            R4: Lang_E.NONAGRI.R4,

        },
        AGRIPROPERTY: {
            HEADER: Lang_E.AGRIPROPERTY.HEADER,
        },
        INVESTMENTS: {
            HEADER: Lang_E.INVESTMENTS.HEADER,
        },
        MOTOR: {
            HEADER: Lang_E.MOTOR.HEADER,
            VEHICLE_TYPE: Lang_E.MOTOR.VEHICLE_TYPE,
            ENGINE: Lang_E.MOTOR.ENGINE,
            REG_No: Lang_E.MOTOR.REG_No,
        },
        FURNITURE: {
            HEADER: Lang_E.FURNITURE.HEADER,
            F1: Lang_E.FURNITURE.F1,
            F2: Lang_E.FURNITURE.F2,
            F3: Lang_E.FURNITURE.F3,
            T1: Lang_E.FURNITURE.T1,
            T2: Lang_E.FURNITURE.T2,
            T3: Lang_E.FURNITURE.T3,
        },
        JEWELLERY: {
            HEADER: Lang_E.JEWELLERY.HEADER,
        },
        ELECTRONIC: {
            HEADER: Lang_E.ELECTRONIC.HEADER,
        },
        CASH: {
            HEADER: Lang_E.CASH.HEADER,
        },
        OTHER: {
            HEADER: Lang_E.OTHER.HEADER,
        },
        RECEIPT: {
            HEADER: Lang_E.RECEIPT.HEADER,
        },
        YEARNET: {
            HEADER: Lang_E.YEARNET.HEADER,
            R1: Lang_E.YEARNET.R1,
        },
        MORTGAGES: {
            HEADER: Lang_E.MORTGAGES.HEADER,
        },
        LOANS: {
            HEADER: Lang_E.LOANS.HEADER,
        },
        BANKLOANS: {
            HEADER: Lang_E.BANKLOANS.HEADER,
            ENTER_OPENING: Lang_E.BANKLOANS.ENTER_OPENING,
            ENTER_LOAN: Lang_E.BANKLOANS.ENTER_LOAN,
            ENTER_PRINCIPAL: Lang_E.BANKLOANS.ENTER_PRINCIPAL,
        },
        OTHERLIA: {
            HEADER: Lang_E.OTHERLIA.HEADER,
        },
        FOOD: {
            HEADER: Lang_E.FOOD.HEADER,
            ITEM1: Lang_E.FOOD.ITEM1,
            ITEM2: Lang_E.FOOD.ITEM2,
            ITEM3: Lang_E.FOOD.ITEM3,
        },
        HOUSING: {
            HEADER: Lang_E.HOUSING.HEADER,
            ITEM1: Lang_E.HOUSING.ITEM1,
        },
        AUTO: {
            HEADER: Lang_E.AUTO.HEADER,
            ITEM1: Lang_E.AUTO.ITEM1,
            ITEM2: Lang_E.AUTO.ITEM2,
        },
        HOUSEHOLD: {
            HEADER: Lang_E.HOUSEHOLD.HEADER,
            ITEM1: Lang_E.HOUSEHOLD.ITEM1,
            ITEM2: Lang_E.HOUSEHOLD.ITEM2,
            ITEM3: Lang_E.HOUSEHOLD.ITEM3,
            ITEM4: Lang_E.HOUSEHOLD.ITEM4,
        },
        EDUCATION: {
            HEADER: Lang_E.EDUCATION.HEADER,
            ITEM1: Lang_E.EDUCATION.ITEM1,
        },
        SPECIAL: {
            HEADER: Lang_E.SPECIAL.HEADER,
            ITEM1: Lang_E.SPECIAL.ITEM1,
            ITEM2: Lang_E.SPECIAL.ITEM2,
            ITEM3: Lang_E.SPECIAL.ITEM3,
            ITEM4: Lang_E.SPECIAL.ITEM4,
        },
        OTHEREXP: {
            HEADER: Lang_E.OTHEREXP.HEADER,
            ITEM1: Lang_E.OTHEREXP.ITEM1,
        },
        LOSSEXP: {
            HEADER: Lang_E.LOSSEXP.HEADER,
            ITEM1: Lang_E.LOSSEXP.ITEM1,
        },
        GIFTEXP: {
            HEADER: Lang_E.GIFTEXP.HEADER,
            ITEM1: Lang_E.GIFTEXP.ITEM1,
        },
        TAXCHARGES: {
            HEADER: Lang_E.TAXCHARGES.HEADER,
            ITEM1: Lang_E.TAXCHARGES.ITEM1,
            ITEM2: Lang_E.TAXCHARGES.ITEM2,
        },


        HOME: {
            TAGLINE_1: Lang_E.HOME.TAGLINE_1,
            TAGLINE_2: Lang_E.HOME.TAGLINE_2,
            NAME: Lang_E.HOME.NAME,
            OFFER_SECTION: Lang_E.HOME.OFFER_SECTION,
            OFFER_1: Lang_E.HOME.OFFER_1,
            OFFER_2: Lang_E.HOME.OFFER_2,
            OFFER_3: Lang_E.HOME.OFFER_3,
            OFFER_1_TEXT: Lang_E.HOME.OFFER_1_TEXT,
            OFFER_2_TEXT: Lang_E.HOME.OFFER_2_TEXT,
            OFFER_3_TEXT: Lang_E.HOME.OFFER_3_TEXT,
            CHOOSE_SECTION: Lang_E.HOME.CHOOSE_SECTION,
            CHOOSE_1: Lang_E.HOME.CHOOSE_1,
            CHOOSE_2: Lang_E.HOME.CHOOSE_2,
            CHOOSE_3: Lang_E.HOME.CHOOSE_3,
            CHOOSE_4: Lang_E.HOME.CHOOSE_4,
            CHOOSE_5: Lang_E.HOME.CHOOSE_5,
            CHOOSE_6: Lang_E.HOME.CHOOSE_6,
            CHOOSE_7: Lang_E.HOME.CHOOSE_7,
            CHOOSE_8: Lang_E.HOME.CHOOSE_8,
            CHOOSE_9: Lang_E.HOME.CHOOSE_9,
            CHOOSE_10: Lang_E.HOME.CHOOSE_10,
            CHOOSE_11: Lang_E.HOME.CHOOSE_11,
            CHOOSE_1_TEXT: Lang_E.HOME.CHOOSE_1_TEXT,
            CHOOSE_2_TEXT: Lang_E.HOME.CHOOSE_2_TEXT,
            CHOOSE_3_TEXT: Lang_E.HOME.CHOOSE_3_TEXT,
            CHOOSE_4_TEXT: Lang_E.HOME.CHOOSE_4_TEXT,
            CHOOSE_5_TEXT: Lang_E.HOME.CHOOSE_5_TEXT,
            CHOOSE_6_TEXT: Lang_E.HOME.CHOOSE_6_TEXT,
            CHOOSE_7_TEXT: Lang_E.HOME.CHOOSE_7_TEXT,
            CHOOSE_8_TEXT: Lang_E.HOME.CHOOSE_8_TEXT,
            CHOOSE_9_TEXT: Lang_E.HOME.CHOOSE_9_TEXT,
            CHOOSE_10_TEXT: Lang_E.HOME.CHOOSE_10_TEXT,
            CHOOSE_11_TEXT: Lang_E.HOME.CHOOSE_11_TEXT,
            V_TAG_1: Lang_E.HOME.V_TAG_1,
            V_TAG_2: Lang_E.HOME.V_TAG_2,
            V_TAG_3: Lang_E.HOME.V_TAG_3,
            V_TAG_4: Lang_E.HOME.V_TAG_4,
            V_TAG_5: Lang_E.HOME.V_TAG_5,
            V_TAG_6: Lang_E.HOME.V_TAG_6,
            V_TAG_7: Lang_E.HOME.V_TAG_7,
            V_TAG_8: Lang_E.HOME.V_TAG_8,
        },
        ABOUT: {
            HEADER: Lang_E.ABOUT.HEADER,
            TAGLINE: Lang_E.ABOUT.TAGLINE,
            ABOUT_1: Lang_E.ABOUT.ABOUT_1,
            ABOUT_2: Lang_E.ABOUT.ABOUT_2,
            ABOUT_3: Lang_E.ABOUT.ABOUT_3,
            ABOUT_4: Lang_E.ABOUT.ABOUT_4,
            ABOUT_5: Lang_E.ABOUT.ABOUT_5,
            ABOUT_6: Lang_E.ABOUT.ABOUT_6,
            CHOOSE_0: Lang_E.ABOUT.CHOOSE_0,
            CHOOSE_1: Lang_E.ABOUT.CHOOSE_1,
            CHOOSE_2: Lang_E.ABOUT.CHOOSE_2,
            CHOOSE_3: Lang_E.ABOUT.CHOOSE_3,
            CHOOSE_4: Lang_E.ABOUT.CHOOSE_4,
            CHOOSE_5: Lang_E.ABOUT.CHOOSE_5,
            CHOOSE_6: Lang_E.ABOUT.CHOOSE_6,
            CHOOSE_7: Lang_E.ABOUT.CHOOSE_7,
            CHOOSE_10: Lang_E.HOME.CHOOSE_10,
            CHOOSE_11: Lang_E.HOME.CHOOSE_11,
        },
        PRICING: {
            HEADER: Lang_E.PRICING.HEADER,
            SUB_HEADER: Lang_E.PRICING.SUB_HEADER,
            TABLE_HEADER_PLANS: Lang_E.PRICING.TABLE_HEADER_PLANS,
            TABLE_HEADER_2: Lang_E.PRICING.TABLE_HEADER_2,
            TABLE_HEADER_STANDARD: Lang_E.PRICING.TABLE_HEADER_STANDARD,
            TABLE_HEADER_PREMIUM: Lang_E.PRICING.TABLE_HEADER_PREMIUM,
            TABLE_HEADER_YEAR: Lang_E.PRICING.TABLE_HEADER_YEAR,
            TABLE_HEADER_FILE_YEAR: Lang_E.PRICING.TABLE_HEADER_FILE_YEAR,
            TABLE_HEADER_0: Lang_E.PRICING.TABLE_HEADER_0,
            TABLE_HEADER_300: Lang_E.PRICING.TABLE_HEADER_300,
            TABLE_HEADER_200: Lang_E.PRICING.TABLE_HEADER_200,
            TABLE_HEADER_800: Lang_E.PRICING.TABLE_HEADER_800,
            TABLE_HEADER_150: Lang_E.PRICING.TABLE_HEADER_150,
            TABLE_HEADER_250: Lang_E.PRICING.TABLE_HEADER_250,
            TABLE_HEADER_100: Lang_E.PRICING.TABLE_HEADER_100,
            TABLE_HEADER_PRO: Lang_E.PRICING.TABLE_HEADER_PRO,
            TABLE_HEADER_ENT: Lang_E.PRICING.TABLE_HEADER_ENT,
            TABLE_HEADER_5c: Lang_E.PRICING.TABLE_HEADER_5c,
            TABLE_HEADER_50c: Lang_E.PRICING.TABLE_HEADER_50c,
            TABLE_HEADER_100c: Lang_E.PRICING.TABLE_HEADER_100c,
            TABLE_HEADER_UN: Lang_E.PRICING.TABLE_HEADER_UN,
            TABLE_HEADER_FREE: Lang_E.PRICING.TABLE_HEADER_FREE,
            TABLE_HEADER_18: Lang_E.PRICING.TABLE_HEADER_18,
            TABLE_HEADER_19: Lang_E.PRICING.TABLE_HEADER_19,
            TABLE_HEADER_STARTER: Lang_E.PRICING.TABLE_HEADER_STARTER,
            TABLE_HEADER_499: Lang_E.PRICING.TABLE_HEADER_499,
            TABLE_HEADER_999: Lang_E.PRICING.TABLE_HEADER_999,
            TABLE_HEADER_1999: Lang_E.PRICING.TABLE_HEADER_1999,
            PLAN_1: Lang_E.PRICING.PLAN_1,
            PLAN_1_s: Lang_E.PRICING.PLAN_1_s,
            PLAN_2: Lang_E.PRICING.PLAN_2,
            PLAN_2_s: Lang_E.PRICING.PLAN_2_s,
            PLAN_TEXT_1: Lang_E.PRICING.PLAN_TEXT_1,
            PLAN_TEXT_2: Lang_E.PRICING.PLAN_TEXT_2,
            PLAN_TEXT_3: Lang_E.PRICING.PLAN_TEXT_3,
            PLAN_TEXT_4: Lang_E.PRICING.PLAN_TEXT_4,
            PLAN_TEXT_5: Lang_E.PRICING.PLAN_TEXT_5,
            PLAN_TEXT_6: Lang_E.PRICING.PLAN_TEXT_6,
            PLAN_TEXT_7: Lang_E.PRICING.PLAN_TEXT_7,
            PLAN_TEXT_8: Lang_E.PRICING.PLAN_TEXT_8,
            PLAN_TEXT_9: Lang_E.PRICING.PLAN_TEXT_9,
            PLAN_TEXT_9a: Lang_E.PRICING.PLAN_TEXT_9a,
            PLAN_TEXT_10: Lang_E.PRICING.PLAN_TEXT_10,
            PLAN_TEXT_11: Lang_E.PRICING.PLAN_TEXT_11,
            PLAN_TEXT_12: Lang_E.PRICING.PLAN_TEXT_12,
            PLAN_TEXT_13: Lang_E.PRICING.PLAN_TEXT_13,
            PLAN_TEXT_14: Lang_E.PRICING.PLAN_TEXT_14,
            PLAN_TEXT_15: Lang_E.PRICING.PLAN_TEXT_15,
            PLAN_TEXT_16: Lang_E.PRICING.PLAN_TEXT_16,
            PLAN_TEXT_17: Lang_E.PRICING.PLAN_TEXT_17,
            R1: Lang_E.PRICING.R1,
            R2: Lang_E.PRICING.R2,
            R3: Lang_E.PRICING.R3,
            R4: Lang_E.PRICING.R4,
            R5: Lang_E.PRICING.R5,
            R6: Lang_E.PRICING.R6,
            R7: Lang_E.PRICING.R7,
            R8: Lang_E.PRICING.R8,
            R9: Lang_E.PRICING.R9,
            R10: Lang_E.PRICING.R10,
            R11: Lang_E.PRICING.R11,
            R12: Lang_E.PRICING.R12,
            R13: Lang_E.PRICING.R13,
            R14: Lang_E.PRICING.R14,
            R15: Lang_E.PRICING.R15,

        },
        NEWS: {
            HEADER: Lang_E.NEWS.HEADER,
            HEADER_SUB_N: Lang_E.NEWS.HEADER_SUB_N,
            HEADER_SUB_O: Lang_E.NEWS.HEADER_SUB_O,
            HEADER_SUB_D: Lang_E.NEWS.HEADER_SUB_D,
        },
        BLOG: {
            HEADER: Lang_E.BLOG.HEADER,
            R1: Lang_E.BLOG.R1,
            R2: Lang_E.BLOG.R2,
            R3: Lang_E.BLOG.R3,
            R4: Lang_E.BLOG.R4,
            R5: Lang_E.BLOG.R5,
            R6: Lang_E.BLOG.R6,
            R7: Lang_E.BLOG.R7,
            R8: Lang_E.BLOG.R8,
            R9: Lang_E.BLOG.R9,
        },
        FAQ: {
            HEADER: Lang_E.FAQ.HEADER,
            R1: Lang_E.FAQ.R1,
            R2: Lang_E.FAQ.R2,
            TAX_01_Q: Lang_E.FAQ.TAX_01_Q,
            TAX_01_A: Lang_E.FAQ.TAX_01_A,
            TAX_02_Q: Lang_E.FAQ.TAX_02_Q,
            TAX_02_A: Lang_E.FAQ.TAX_02_A,
            TAX_03_Q: Lang_E.FAQ.TAX_03_Q,
            TAX_03_A: Lang_E.FAQ.TAX_03_A,
            TAX_04_Q: Lang_E.FAQ.TAX_04_Q,
            TAX_04_A: Lang_E.FAQ.TAX_04_A,
            TAX_05_Q: Lang_E.FAQ.TAX_05_Q,
            TAX_05_A: Lang_E.FAQ.TAX_05_A,
            TAX_06_Q: Lang_E.FAQ.TAX_06_Q,
            TAX_06_A: Lang_E.FAQ.TAX_06_A,
            TAX_07_Q: Lang_E.FAQ.TAX_07_Q,
            TAX_07_A: Lang_E.FAQ.TAX_07_A,
            TAX_08_Q: Lang_E.FAQ.TAX_08_Q,
            TAX_08_A: Lang_E.FAQ.TAX_08_A,
            TAX_09_Q: Lang_E.FAQ.TAX_09_Q,
            TAX_09_A: Lang_E.FAQ.TAX_09_A,
            TAX_10_Q: Lang_E.FAQ.TAX_10_Q,
            TAX_10_A: Lang_E.FAQ.TAX_10_A,
            TAX_11_Q: Lang_E.FAQ.TAX_11_Q,
            TAX_11_A: Lang_E.FAQ.TAX_11_A,
            TAX_12_Q: Lang_E.FAQ.TAX_12_Q,
            TAX_12_A: Lang_E.FAQ.TAX_12_A,
            TAX_13_Q: Lang_E.FAQ.TAX_13_Q,
            TAX_13_A: Lang_E.FAQ.TAX_13_A,
            TAX_14_Q: Lang_E.FAQ.TAX_14_Q,
            TAX_14_A: Lang_E.FAQ.TAX_14_A,
            GEN_01_Q: Lang_E.FAQ.GEN_01_Q,
            GEN_01_A: Lang_E.FAQ.GEN_01_A,
            GEN_02_Q: Lang_E.FAQ.GEN_02_Q,
            GEN_02_A: Lang_E.FAQ.GEN_02_A,
            GEN_03_Q: Lang_E.FAQ.GEN_03_Q,
            GEN_03_A: Lang_E.FAQ.GEN_03_A,
            GEN_04_Q: Lang_E.FAQ.GEN_04_Q,
            GEN_04_A: Lang_E.FAQ.GEN_04_A,
            GEN_05_Q: Lang_E.FAQ.GEN_05_Q,
            GEN_05_A: Lang_E.FAQ.GEN_05_A,
            GEN_06_Q: Lang_E.FAQ.GEN_06_Q,
            GEN_06_A: Lang_E.FAQ.GEN_06_A,
            GEN_07_Q: Lang_E.FAQ.GEN_07_Q,
            GEN_07_A: Lang_E.FAQ.GEN_07_A,
            GEN_08_Q: Lang_E.FAQ.GEN_08_Q,
            GEN_08_A: Lang_E.FAQ.GEN_08_A,
            GEN_09_Q: Lang_E.FAQ.GEN_09_Q,
            GEN_09_A: Lang_E.FAQ.GEN_09_A,
            GEN_10_Q: Lang_E.FAQ.GEN_10_Q,
            GEN_10_A: Lang_E.FAQ.GEN_10_A,
        },
        CONTACT: {
            HEADER: Lang_E.CONTACT.HEADER,
            HEADER_2: Lang_E.CONTACT.HEADER_2,
            HEADER_3: Lang_E.CONTACT.HEADER_3,
            CONTACT_US: Lang_E.CONTACT.CONTACT_US,
            EMAIL: Lang_E.CONTACT.EMAIL,
            LOCATION: Lang_E.CONTACT.LOCATION,
            HELPLINE: Lang_E.CONTACT.HELPLINE,
            ADDRESS: Lang_E.CONTACT.ADDRESS,
            R5: Lang_E.CONTACT.R5,
            R6: Lang_E.CONTACT.R6,
            R7: Lang_E.CONTACT.R7,
            ADDRESS_1: Lang_E.CONTACT.ADDRESS_1,
            ADDRESS_2: Lang_E.CONTACT.ADDRESS_2,
            ADDRESS_3: Lang_E.CONTACT.ADDRESS_3,
        },
        HOW_IT_WORKS: {
            HEADER: Lang_E.HOW_IT_WORKS.HEADER,
            HEADER_1: Lang_E.HOW_IT_WORKS.HEADER_1,
            HEADER_2: Lang_E.HOW_IT_WORKS.HEADER_2,
            TAB_1_HEADER: Lang_E.HOW_IT_WORKS.TAB_1_HEADER,
            TAB_1_ITEM_1: Lang_E.HOW_IT_WORKS.TAB_1_ITEM_1,
            TAB_1_ITEM_2: Lang_E.HOW_IT_WORKS.TAB_1_ITEM_2,
            TAB_1_ITEM_3: Lang_E.HOW_IT_WORKS.TAB_1_ITEM_3,
            TAB_2_HEADER: Lang_E.HOW_IT_WORKS.TAB_2_HEADER,
            TAB_2_ITEM_1: Lang_E.HOW_IT_WORKS.TAB_2_ITEM_1,
            TAB_2_ITEM_2: Lang_E.HOW_IT_WORKS.TAB_2_ITEM_2,
            TAB_2_ITEM_3: Lang_E.HOW_IT_WORKS.TAB_2_ITEM_3,
            TAB_2_ITEM_4: Lang_E.HOW_IT_WORKS.TAB_2_ITEM_4,
            TAB_2_ITEM_5: Lang_E.HOW_IT_WORKS.TAB_2_ITEM_5,
            TAB_3_HEADER: Lang_E.HOW_IT_WORKS.TAB_3_HEADER,
            TAB_3_ITEM_1: Lang_E.HOW_IT_WORKS.TAB_3_ITEM_1,
            TAB_3_ITEM_2: Lang_E.HOW_IT_WORKS.TAB_3_ITEM_2,
            TAB_3_ITEM_3: Lang_E.HOW_IT_WORKS.TAB_3_ITEM_3,
            TAB_4_HEADER: Lang_E.HOW_IT_WORKS.TAB_4_HEADER,
            TAB_4_ITEM_1: Lang_E.HOW_IT_WORKS.TAB_4_ITEM_1,
            TAB_4_ITEM_2: Lang_E.HOW_IT_WORKS.TAB_4_ITEM_2,
            TAB_4_ITEM_3: Lang_E.HOW_IT_WORKS.TAB_4_ITEM_3,
        },
        PROFILE: {
            HEADER: Lang_E.PROFILE.HEADER,
            OrgName: Lang_E.PROFILE.OrgName,
            ITPNo: Lang_E.PROFILE.ITPNo,
            BarMembershipNo: Lang_E.PROFILE.BarMembershipNo,
            Mobile: Lang_E.PROFILE.Mobile,
            Email: Lang_E.PROFILE.Email,
            Address1: Lang_E.PROFILE.Address1,
            Address2: Lang_E.PROFILE.Address2,
            ENTER_OrgName: Lang_E.PROFILE.ENTER_OrgName,
            ENTER_ITPNo: Lang_E.PROFILE.ENTER_ITPNo,
            ENTER_BarMembershipNo: Lang_E.PROFILE.ENTER_BarMembershipNo,
            ENTER_Mobile: Lang_E.PROFILE.ENTER_Mobile,
            ENTER_Email: Lang_E.PROFILE.ENTER_Email,
            ENTER_Address2: Lang_E.PROFILE.ENTER_Address2,
            ENTER_Profile: Lang_E.PROFILE.ENTER_Profile,

        },
        CLIENT: {
            HEADER: Lang_E.CLIENT.HEADER,
            ADD: Lang_E.CLIENT.ADD,
            ETIN: Lang_E.CLIENT.ETIN,
            AssesseeName: Lang_E.CLIENT.AssesseeName,
            Mobile: Lang_E.CLIENT.Mobile,
            Email: Lang_E.CLIENT.Email,
            ENTER_ETIN: Lang_E.CLIENT.ENTER_ETIN,
            ENTER_AssesseeName: Lang_E.CLIENT.ENTER_AssesseeName,
            ENTER_Mobile: Lang_E.CLIENT.ENTER_Mobile,
            ENTER_Email: Lang_E.CLIENT.ENTER_Email,
            TABLE_HEADER_ACTIONS: Lang_E.CLIENT.TABLE_HEADER_ACTIONS,
            TABLE_HEADER_PROGRESS: Lang_E.CLIENT.TABLE_HEADER_PROGRESS,
            TABLE_HEADER_ETIN: Lang_E.CLIENT.TABLE_HEADER_ETIN,
            TABLE_HEADER_NAME: Lang_E.CLIENT.TABLE_HEADER_NAME,
            TABLE_HEADER_MOBILE: Lang_E.CLIENT.TABLE_HEADER_MOBILE,
            TABLE_HEADER_EMAIL: Lang_E.CLIENT.TABLE_HEADER_EMAIL,
            TABLE_HEADER_TAX: Lang_E.CLIENT.TABLE_HEADER_TAX,
            TABLE_HEADER_TOTAL_DUES: Lang_E.CLIENT.TABLE_HEADER_TOTAL_DUES,
            TABLE_HEADER_REFERENCE: Lang_E.CLIENT.TABLE_HEADER_REFERENCE,
        },
        AUTH_TRAN: {
            BTN_LOGIN: Lang_E.AUTH_TRAN.BTN_LOGIN,
            BTN_LOGOUT: Lang_E.AUTH_TRAN.BTN_LOGOUT,
            BTN_LOGIN_S: Lang_E.AUTH_TRAN.BTN_LOGIN_S,
            BTN_REGISTER: Lang_E.AUTH_TRAN.BTN_REGISTER,
            LOGIN_HEADER: Lang_E.AUTH_TRAN.LOGIN_HEADER,
            PLACEHOLDER_NAME: Lang_E.AUTH_TRAN.PLACEHOLDER_NAME,
            PLACEHOLDER_EMAIL: Lang_E.AUTH_TRAN.PLACEHOLDER_EMAIL,
            PLACEHOLDER_PASSWORD: Lang_E.AUTH_TRAN.PLACEHOLDER_PASSWORD,
            PLACEHOLDER_N_PASSWORD: Lang_E.AUTH_TRAN.PLACEHOLDER_N_PASSWORD,
            PLACEHOLDER_C_PASSWORD: Lang_E.AUTH_TRAN.PLACEHOLDER_C_PASSWORD,
            FORGOT_PASSWORD: Lang_E.AUTH_TRAN.FORGOT_PASSWORD,
            TEXT_OR: Lang_E.AUTH_TRAN.TEXT_OR,
            TEXT_ACCEPT_TA: Lang_E.AUTH_TRAN.TEXT_ACCEPT_TA,
            TEXT_NO_ACCOUNT: Lang_E.AUTH_TRAN.TEXT_NO_ACCOUNT,
            TEXT_AL_ACCOUNT: Lang_E.AUTH_TRAN.TEXT_AL_ACCOUNT,
            TEXT_CREATE_ACCOUNT: Lang_E.AUTH_TRAN.TEXT_CREATE_ACCOUNT,
            TEXT_CREATE_ACCOUNT_IND: Lang_E.AUTH_TRAN.TEXT_CREATE_ACCOUNT_IND,
            TEXT_CREATE_ACCOUNT_PRO: Lang_E.AUTH_TRAN.TEXT_CREATE_ACCOUNT_PRO,
            REGISTER_HEADER: Lang_E.AUTH_TRAN.REGISTER_HEADER,
            TYPE_IND: Lang_E.AUTH_TRAN.TYPE_IND,
            TYPE_PRO: Lang_E.AUTH_TRAN.TYPE_PRO,
            TEXT_CONFIRM: Lang_E.AUTH_TRAN.TEXT_CONFIRM,
            TEXT_CONFIRM_EMAIL: Lang_E.AUTH_TRAN.TEXT_CONFIRM_EMAIL,
            TEXT_CONFIRM_INBOX: Lang_E.AUTH_TRAN.TEXT_CONFIRM_INBOX,
            TEXT_VERIFY_B: Lang_E.AUTH_TRAN.TEXT_VERIFY_B,
            TEXT_CONGRAT: Lang_E.AUTH_TRAN.TEXT_CONGRAT,
            TEXT_CONFIRMED: Lang_E.AUTH_TRAN.TEXT_CONFIRMED,
            TEXT_BACK_LOGIN: Lang_E.AUTH_TRAN.TEXT_BACK_LOGIN,
            TEXT_RECOVER: Lang_E.AUTH_TRAN.TEXT_RECOVER,
            TEXT_SEND_LINK: Lang_E.AUTH_TRAN.TEXT_SEND_LINK,
            TEXT_RESET: Lang_E.AUTH_TRAN.TEXT_RESET,
            TEXT_SET: Lang_E.AUTH_TRAN.TEXT_SET,



        },
        DOCUMENTS: {
            HEADER: Lang_E.DOCUMENTS.HEADER,
            HEADER_1: Lang_E.DOCUMENTS.HEADER_1,
            FILE_NAME: Lang_E.DOCUMENTS.FILE_NAME,
            SEARCH_1: Lang_E.DOCUMENTS.SEARCH_1,
            FILE_TH: Lang_E.DOCUMENTS.FILE_TH,
            FILE_T: Lang_E.DOCUMENTS.FILE_T,
            FILE_SIZE_TH: Lang_E.DOCUMENTS.FILE_SIZE_TH,
            UPLOAD_DATE_TH: Lang_E.DOCUMENTS.UPLOAD_DATE_TH,
            COMMENTS_TH: Lang_E.DOCUMENTS.COMMENTS_TH,
            ACTION_TH: Lang_E.DOCUMENTS.ACTION_TH,
            FILE_N: Lang_E.DOCUMENTS.FILE_N,
            DOC_ADD: Lang_E.DOCUMENTS.DOC_ADD,
            DOC_REP: Lang_E.DOCUMENTS.DOC_REP,
            DOC_ALL: Lang_E.DOCUMENTS.DOC_ALL,
            DOC_COMMENT_ADD: Lang_E.DOCUMENTS.DOC_COMMENT_ADD,
            DOC_Upload: Lang_E.DOCUMENTS.DOC_Upload,
            DOC_DRAG: Lang_E.DOCUMENTS.DOC_DRAG,
            DOC_OR: Lang_E.DOCUMENTS.DOC_OR,
            DOC_SELECT: Lang_E.DOCUMENTS.DOC_SELECT,
            DOC_DL_FILE: Lang_E.DOCUMENTS.DOC_DL_FILE,
        },
        ARCHIVE: {
            HEADER: Lang_E.ARCHIVE.HEADER,
            HEADER_1: Lang_E.ARCHIVE.HEADER_1,
            BUTTON_S: Lang_E.ARCHIVE.BUTTON_S,
            NOTICE_H: Lang_E.ARCHIVE.NOTICE_H,
            NOTICE_1: Lang_E.ARCHIVE.NOTICE_1,
            NOTICE_2: Lang_E.ARCHIVE.NOTICE_2,
            NOTICE_3: Lang_E.ARCHIVE.NOTICE_3,
            NOTICE_4: Lang_E.ARCHIVE.NOTICE_4,
        }

    }
};

export const bangla = {
    lang: "bn",
    data: {
        COMMON: {
            BUTTON_SAVE_NEXT: Lang_B.COMMON.BUTTON_SAVE_NEXT,
            BUTTON_SAVE_EXIT: Lang_B.COMMON.BUTTON_SAVE_EXIT,
            BUTTON_SAVE: Lang_B.COMMON.BUTTON_SAVE,
            BUTTON_ADD: Lang_B.COMMON.BUTTON_ADD,
            BUTTON_UPDATE: Lang_B.COMMON.BUTTON_UPDATE,
            BUTTON_SAVE_ADD_MORE: Lang_B.COMMON.BUTTON_SAVE_ADD_MORE,
            BUTTON_ADD_MORE: Lang_B.COMMON.BUTTON_ADD_MORE,
            BUTTON_ADD_MORE_ROWS: Lang_B.COMMON.BUTTON_ADD_MORE_ROWS,
            BUTTON_EDIT: Lang_B.COMMON.BUTTON_EDIT,
            BUTTON_DELETE: Lang_B.COMMON.BUTTON_DELETE,
            BUTTON_DELETED: Lang_B.COMMON.BUTTON_DELETED,
            BUTTON_NEXT: Lang_B.COMMON.BUTTON_NEXT,
            BUTTON_NEXT_PAGE: Lang_B.COMMON.BUTTON_NEXT_PAGE,
            BUTTON_PREVIOUS: Lang_B.COMMON.BUTTON_PREVIOUS,
            BUTTON_PREVIOUS_PAGE: Lang_B.COMMON.BUTTON_PREVIOUS_PAGE,
            BUTTON_AMOUNT: Lang_B.COMMON.BUTTON_AMOUNT,
            BUTTON_COMMENT: Lang_B.COMMON.BUTTON_COMMENT,
            BUTTON_DESC: Lang_B.COMMON.BUTTON_DESC,
            BUTTON_REQ: Lang_B.COMMON.BUTTON_REQ,
            NOTE_DESC: Lang_B.COMMON.NOTE_DESC,
            BUTTON_ROW: Lang_B.COMMON.BUTTON_ROW,
            BUTTON_DATA: Lang_B.COMMON.BUTTON_DATA,
            BUTTON_OK: Lang_B.COMMON.BUTTON_OK,
            BUTTON_WRONG: Lang_B.COMMON.BUTTON_WRONG,
            BUTTON_UPDATED: Lang_B.COMMON.BUTTON_UPDATED,
            BUTTON_ENTER_AMOUNT: Lang_B.COMMON.BUTTON_ENTER_AMOUNT,
            BUTTON_ENTER_DESC: Lang_B.COMMON.BUTTON_ENTER_DESC,
            BUTTON_INTEREST_AMOUNT: Lang_B.COMMON.BUTTON_INTEREST_AMOUNT,
            BUTTON_ALL: Lang_B.COMMON.BUTTON_ALL,
            BUTTON_TYPE: Lang_B.COMMON.BUTTON_TYPE,
            BUTTON_DL: Lang_B.COMMON.BUTTON_DL,
            BUTTON_CONFIRM: Lang_B.COMMON.BUTTON_CONFIRM,
            BUTTON_CANCEL: Lang_B.COMMON.BUTTON_CANCEL,
            LABEL_DELETE: Lang_B.COMMON.LABEL_DELETE,
            LABEL_NOT_SAVED: Lang_B.COMMON.LABEL_NOT_SAVED,
            NAV_HOME: Lang_B.COMMON.NAV_HOME,
            NAV_HOW: Lang_B.COMMON.NAV_HOW,
            NAV_ABOUT: Lang_B.COMMON.NAV_ABOUT,
            NAV_PRICE: Lang_B.COMMON.NAV_PRICE,
            NAV_HELP: Lang_B.COMMON.NAV_HELP,
            NAV_BLOG: Lang_B.COMMON.NAV_BLOG,
            NAV_NEWS: Lang_B.COMMON.NAV_NEWS,
            NAV_CONTACT: Lang_B.COMMON.NAV_CONTACT,
            NAV_TC: Lang_B.COMMON.NAV_TC,
            NAV_PP: Lang_B.COMMON.NAV_PP,
            NAV_RRP: Lang_B.COMMON.NAV_RRP,
            FOOLTER_CI: Lang_B.COMMON.FOOLTER_CI,
            FOOLTER_WORK: Lang_B.COMMON.FOOLTER_WORK,
            FOOLTER_QUE: Lang_B.COMMON.FOOLTER_QUE,
            FOOLTER_SN: Lang_B.COMMON.FOOLTER_SN,
            FOOLTER_QL: Lang_B.COMMON.FOOLTER_QL,
            ITEM_1: Lang_B.COMMON.ITEM_1,
            ITEM_2: Lang_B.COMMON.ITEM_2,
            ITEM_3: Lang_B.COMMON.ITEM_3,
            ITEM_4: Lang_B.COMMON.ITEM_4,
            AUTO_GENERATED: Lang_B.COMMON.AUTO_GENERATED,
            ETIN: Lang_B.COMMON.ETIN,
            TAX_OWED: Lang_B.COMMON.TAX_OWED,
            TAX_YEAR: Lang_B.COMMON.TAX_YEAR,
            SAVE_EXIT: Lang_B.COMMON.SAVE_EXIT,
            CONFIRM_ARCHIVE: Lang_B.COMMON.CONFIRM_ARCHIVE,
            ENTER_ASSET: Lang_B.COMMON.ENTER_ASSET,
        },
        ETIN: {
            HEADER: Lang_B.ETIN.HEADER,
            EXTRA1: Lang_B.ETIN.EXTRA1,
            EXTRA2: Lang_B.ETIN.EXTRA2,
            EXTRA3: Lang_B.ETIN.EXTRA3,
            EXTRA4: Lang_B.ETIN.EXTRA4,
            R_AREA_OF_RESIDENT: Lang_B.ETIN.R_AREA_OF_RESIDENT,
            R_ETIN_12C: Lang_B.ETIN.R_ETIN_12C,
            R_CIRCLE: Lang_B.ETIN.R_CIRCLE,
            R_ZONE: Lang_B.ETIN.R_ZONE,
            R_DISABLED_CHILDREN: Lang_B.ETIN.R_DISABLED_CHILDREN,
            R_NAT_STATUS: Lang_B.ETIN.R_NAT_STATUS,
            R_RES_STATUS: Lang_B.ETIN.R_RES_STATUS,
            R_ETIN_OTHER: Lang_B.ETIN.R_ETIN_OTHER,
            R_DISABILITY_CHANGE: Lang_B.ETIN.R_DISABILITY_CHANGE,
            R_DISABILITY_CHANGE_EXIT: Lang_B.ETIN.R_DISABILITY_CHANGE_EXIT,

        },
        PERSONAL: {
            HEADER: Lang_B.PERSONAL.HEADER,
            R_ENTER_NAME: Lang_B.PERSONAL.R_ENTER_NAME,
            R_SELECT_GENDER: Lang_B.PERSONAL.R_SELECT_GENDER,
            R_DOB: Lang_B.PERSONAL.R_DOB,
            R_MARITAL_STATUS: Lang_B.PERSONAL.R_MARITAL_STATUS,
            R_MOBILE: Lang_B.PERSONAL.R_MOBILE,
            R_PRESENT_ADDRESS: Lang_B.PERSONAL.R_PRESENT_ADDRESS,
            R_PRESENT_DISTRICT: Lang_B.PERSONAL.R_PRESENT_DISTRICT,
            R_PRESENT_POSTAL: Lang_B.PERSONAL.R_PRESENT_POSTAL,
            R_PERMANENT_ADDRESS: Lang_B.PERSONAL.R_PERMANENT_ADDRESS,
            R_SELECT_DISTRICT: Lang_B.PERSONAL.R_SELECT_DISTRICT,
            R_ENTER_POSTAL: Lang_B.PERSONAL.R_ENTER_POSTAL,
            R_ENTER_NID: Lang_B.PERSONAL.R_ENTER_NID,
            R_ENTER_NID_L: Lang_B.PERSONAL.R_ENTER_NID_L,
            R_SPOUSE_NAME: Lang_B.PERSONAL.R_SPOUSE_NAME,
            R_SPOUSE_ETIN: Lang_B.PERSONAL.R_SPOUSE_ETIN,
            R_ENTER_PERSONAL_INFO: Lang_B.PERSONAL.R_ENTER_PERSONAL_INFO,
            T_PERMANENT_ADDRESS: Lang_B.PERSONAL.T_PERMANENT_ADDRESS,
            R_GENDER_CHANGE: Lang_B.PERSONAL.R_GENDER_CHANGE,
            R_GENDER_CHANGE_EXIT: Lang_B.PERSONAL.R_GENDER_CHANGE_EXIT,
        },
        PERSONALIZE: {
            HEADER: Lang_B.PERSONALIZE.HEADER,
            HEADER_2: Lang_B.PERSONALIZE.HEADER_2,
            R_MAIN_SOURCE: Lang_B.PERSONALIZE.R_MAIN_SOURCE,
            R_MAIN_SOURCE_CHANGE: Lang_B.PERSONALIZE.R_MAIN_SOURCE_CHANGE,
            R_INCOME_ASSET_CHANGE: Lang_B.PERSONALIZE.R_INCOME_ASSET_CHANGE,
            R_INCOME_ASSET_CHANGE_EXIT: Lang_B.PERSONALIZE.R_INCOME_ASSET_CHANGE_EXIT,
            R_OGR_NAME: Lang_B.PERSONALIZE.R_OGR_NAME,
            R_OGR_ADDRESS: Lang_B.PERSONALIZE.R_OGR_ADDRESS,
            R_BIN: Lang_B.PERSONALIZE.R_BIN,
            R_PER_RETURN: Lang_B.PERSONALIZE.R_PER_RETURN,
            R_ACQ_ASSETS: Lang_B.PERSONALIZE.R_ACQ_ASSETS,
            ENTER_ASSET_EXPENSE: Lang_B.PERSONALIZE.ENTER_ASSET_EXPENSE,

        },
        SPOUSECHILD: {
            HEADER: Lang_B.SPOUSECHILD.HEADER,
            R1: Lang_B.SPOUSECHILD.R1,
            R2: Lang_B.SPOUSECHILD.R2,
        },
        SALARY: {
            HEADER: Lang_B.SALARY.HEADER,
            R1: Lang_B.SALARY.R1,
            R2: Lang_B.SALARY.R2,
            R3: Lang_B.SALARY.R3,
            R4: Lang_B.SALARY.R4,
            R5: Lang_B.SALARY.R5,
            R6: Lang_B.SALARY.R6,
            R7: Lang_B.SALARY.R7,
            T1: Lang_B.SALARY.T1,
            T2: Lang_B.SALARY.T2,
            T3: Lang_B.SALARY.T3,
            GJH: Lang_B.SALARY.GJH,
            PJH: Lang_B.SALARY.PJH,
            PAY_ALLOWANCE: Lang_B.SALARY.PAY_ALLOWANCE,
            YEARLY_INCOME: Lang_B.SALARY.YEARLY_INCOME,
            Exempted_Income: Lang_B.SALARY.Exempted_Income,
            TAXABLE_INCOME: Lang_B.SALARY.TAXABLE_INCOME,
            Basic_Pay: Lang_B.SALARY.Basic_Pay,
            Basic_Pay_IC: Lang_B.SALARY.Basic_Pay_IC,
            Special_Pay: Lang_B.SALARY.Special_Pay,
            Special_Pay_IC: Lang_B.SALARY.Special_Pay_IC,
            Dearness_Allowance: Lang_B.SALARY.Dearness_Allowance,
            Dearness_Allowance_IC: Lang_B.SALARY.Dearness_Allowance_IC,
            Conveyance_Allowance: Lang_B.SALARY.Conveyance_Allowance,
            Conveyance_Allowance_IC: Lang_B.SALARY.Conveyance_Allowance_IC,
            H_R_A: Lang_B.SALARY.H_R_A,
            H_R_A_IC: Lang_B.SALARY.H_R_A_IC,
            M_A: Lang_B.SALARY.M_A,
            M_A_IC: Lang_B.SALARY.M_A_IC,
            A_S_H_L: Lang_B.SALARY.A_S_H_L,
            I_S_D: Lang_B.SALARY.I_S_D,
            L_F_A: Lang_B.SALARY.L_F_A,
            L_F_A_IC: Lang_B.SALARY.L_F_A_IC,
            L_F_A_C: Lang_B.SALARY.L_F_A_C,
            L_E: Lang_B.SALARY.L_E,
            L_E_IC: Lang_B.SALARY.L_E_IC,
            A_S_S: Lang_B.SALARY.A_S_S,
            A_S_S_IC: Lang_B.SALARY.A_S_S_IC,
            HON: Lang_B.SALARY.HON,
            HON_IC: Lang_B.SALARY.HON_IC,
            OV_A: Lang_B.SALARY.OV_A,
            OV_A_IC: Lang_B.SALARY.OV_A_IC,
            BON: Lang_B.SALARY.BON,
            BON_IC: Lang_B.SALARY.BON_IC,
            OTH_A: Lang_B.SALARY.OTH_A,
            OTH_A_IC: Lang_B.SALARY.OTH_A_IC,
            E_C_P_F: Lang_B.SALARY.E_C_P_F,
            E_C_P_F_IC: Lang_B.SALARY.E_C_P_F_IC,
            I_A_P_F: Lang_B.SALARY.I_A_P_F,
            I_A_P_F_IC: Lang_B.SALARY.I_A_P_F_IC,
            R_O_I: Lang_B.SALARY.R_O_I,
            TR_E: Lang_B.SALARY.TR_E,
            F_U_H_E: Lang_B.SALARY.F_U_H_E,
            OTH: Lang_B.SALARY.OTH,
            OTH_IC: Lang_B.SALARY.OTH_IC,
            ARR_P: Lang_B.SALARY.ARR_P,
            ARR_P_IC: Lang_B.SALARY.ARR_P_IC,
            I_G_F: Lang_B.SALARY.I_G_F,
            I_G_F_IC: Lang_B.SALARY.I_G_F_IC,
            I_W_P_F: Lang_B.SALARY.I_W_P_F,
            I_W_P_F_IC: Lang_B.SALARY.I_W_P_F_IC,
            PEN: Lang_B.SALARY.PEN,
            PEN_IC: Lang_B.SALARY.PEN_IC,
            I_P_S_A: Lang_B.SALARY.I_P_S_A,
            I_P_S_A_IC: Lang_B.SALARY.I_P_S_A_IC,
            Organization_Name: Lang_B.SALARY.Organization_Name,
            Organization_Address: Lang_B.SALARY.Organization_Address,
            Buisness_Identification_No: Lang_B.SALARY.Buisness_Identification_No,
            Deemed_Income_Transport: Lang_B.SALARY.Deemed_Income_Transport,
            Deemed_Free_Accommodation: Lang_B.SALARY.Deemed_Free_Accommodation,
            Festival_Bonus: Lang_B.SALARY.Festival_Bonus,
            Bengali_NewYear_Bonus: Lang_B.SALARY.Bengali_NewYear_Bonus,
            Festival_Allowance: Lang_B.SALARY.Festival_Allowance,
            SALARY_TYPE: Lang_B.SALARY.SALARY_TYPE,
            SALARY_TYPE_R_GOV: Lang_B.SALARY.SALARY_TYPE_R_GOV,
            SALARY_TYPE_R_PRI: Lang_B.SALARY.SALARY_TYPE_R_PRI,
        },
        SECURITIES: {
            HEADER: Lang_B.SECURITIES.HEADER,
        },
        PROPERTY: {
            HEADER: Lang_B.PROPERTY.HEADER,
            R1: Lang_B.PROPERTY.R1,
            R2: Lang_B.PROPERTY.R2,
            R3: Lang_B.PROPERTY.R3,
        },
        AGRICULTURE: {
            HEADER: Lang_B.AGRICULTURE.HEADER,
            R1: Lang_B.AGRICULTURE.R1,
            R2: Lang_B.AGRICULTURE.R2,
        },
        BUSINESS: {
            HEADER: Lang_B.BUSINESS.HEADER,
            R1: Lang_B.BUSINESS.R1,
            R2: Lang_B.BUSINESS.R2,
            R3: Lang_B.BUSINESS.R3,
        },
        SHAREPROFIT: {
            HEADER: Lang_B.SHAREPROFIT.HEADER,
            ENTER_NAME: Lang_B.SHAREPROFIT.ENTER_NAME,
            ENTER_INCOME: Lang_B.SHAREPROFIT.ENTER_INCOME,
            ENTER_OWNERSSHIP: Lang_B.SHAREPROFIT.ENTER_OWNERSSHIP,
        },
        CAPITALGAINS: {
            HEADER: Lang_B.CAPITALGAINS.HEADER,
            R1: Lang_B.CAPITALGAINS.R1,
            R2: Lang_B.CAPITALGAINS.R2,
            R3: Lang_B.CAPITALGAINS.R3,
        },
        OTHERSOURCES: {
            HEADER: Lang_B.OTHERSOURCES.HEADER,
            HEADER_1: Lang_B.OTHERSOURCES.HEADER_1,
            HEADER_2: Lang_B.OTHERSOURCES.HEADER_2,
            HEADER_3: Lang_B.OTHERSOURCES.HEADER_3,
            HEADER_4: Lang_B.OTHERSOURCES.HEADER_4,
            InterestMutualOrUnitFund: Lang_B.OTHERSOURCES.InterestMutualOrUnitFund,
            CashDividendStockExchange: Lang_B.OTHERSOURCES.CashDividendStockExchange,
            InterestIncomeWEDB: Lang_B.OTHERSOURCES.InterestIncomeWEDB,
            USDollarPremiumInvestmentBond: Lang_B.OTHERSOURCES.USDollarPremiumInvestmentBond,
            PoundSterlingPremiumInvestmentBond: Lang_B.OTHERSOURCES.PoundSterlingPremiumInvestmentBond,
            EuroPremiumInvestmentBond: Lang_B.OTHERSOURCES.EuroPremiumInvestmentBond,
            ZeroCouponBond: Lang_B.OTHERSOURCES.ZeroCouponBond,
            InvestmentPensionerSavingsInstrument: Lang_B.OTHERSOURCES.InvestmentPensionerSavingsInstrument,
            InterestReceivedPensionerSavingsInstrument: Lang_B.OTHERSOURCES.InterestReceivedPensionerSavingsInstrument,
            SumReceivedBecauseOfPositionLikeDirectorFee: Lang_B.OTHERSOURCES.SumReceivedBecauseOfPositionLikeDirectorFee,
            HonoriumWritingArticle: Lang_B.OTHERSOURCES.HonoriumWritingArticle,
            RemunerationExaminerInvigilator: Lang_B.OTHERSOURCES.RemunerationExaminerInvigilator,
            IncomeTuition: Lang_B.OTHERSOURCES.IncomeTuition,
            IncomeTelevisionRadioParticipatingProgram: Lang_B.OTHERSOURCES.IncomeTelevisionRadioParticipatingProgram,
            Others: Lang_B.OTHERSOURCES.Others,
            Total: Lang_B.OTHERSOURCES.Total,
        },
        FOREIGNSOURCES: {
            HEADER: Lang_B.FOREIGNSOURCES.HEADER,
            R1: Lang_B.FOREIGNSOURCES.R1,
        },
        REBATE: {
            HEADER: Lang_B.REBATE.HEADER,
        },
        TDS: {
            HEADER: Lang_B.TDS.HEADER,
        },
        ADVANCE: {
            HEADER: Lang_B.ADVANCE.HEADER,
        },
        ADJUSTMENT: {
            HEADER: Lang_B.ADJUSTMENT.HEADER,
            R1: Lang_B.ADJUSTMENT.R1,
        },
        CAPITAL: {
            HEADER: Lang_B.CAPITAL.HEADER,
        },
        DS: {
            HEADER: Lang_B.DS.HEADER,
            R1: Lang_B.DS.R1,
            R2: Lang_B.DS.R2,
        },
        NONAGRI: {
            HEADER: Lang_B.NONAGRI.HEADER,
            R1: Lang_B.NONAGRI.R1,
            R2: Lang_B.NONAGRI.R2,
            R3: Lang_B.NONAGRI.R3,
            R4: Lang_B.NONAGRI.R4,
        },
        AGRIPROPERTY: {
            HEADER: Lang_B.AGRIPROPERTY.HEADER,
        },
        INVESTMENTS: {
            HEADER: Lang_B.INVESTMENTS.HEADER,
        },
        MOTOR: {
            HEADER: Lang_B.MOTOR.HEADER,
            VEHICLE_TYPE: Lang_B.MOTOR.VEHICLE_TYPE,
            ENGINE: Lang_B.MOTOR.ENGINE,
            REG_No: Lang_B.MOTOR.REG_No,
        },
        FURNITURE: {
            HEADER: Lang_B.FURNITURE.HEADER,
            F1: Lang_B.FURNITURE.F1,
            F2: Lang_B.FURNITURE.F2,
            F3: Lang_B.FURNITURE.F3,
            T1: Lang_B.FURNITURE.T1,
            T2: Lang_B.FURNITURE.T2,
            T3: Lang_B.FURNITURE.T3,
        },
        JEWELLERY: {
            HEADER: Lang_B.JEWELLERY.HEADER,
        },
        ELECTRONIC: {
            HEADER: Lang_B.ELECTRONIC.HEADER,
        },
        CASH: {
            HEADER: Lang_B.CASH.HEADER,
        },
        OTHER: {
            HEADER: Lang_B.OTHER.HEADER,
        },
        RECEIPT: {
            HEADER: Lang_B.RECEIPT.HEADER,
        },
        YEARNET: {
            HEADER: Lang_B.YEARNET.HEADER,
            R1: Lang_B.YEARNET.R1,
        },
        MORTGAGES: {
            HEADER: Lang_B.MORTGAGES.HEADER,
        },
        LOANS: {
            HEADER: Lang_B.LOANS.HEADER,
        },
        BANKLOANS: {
            HEADER: Lang_B.BANKLOANS.HEADER,
            ENTER_OPENING: Lang_B.BANKLOANS.ENTER_OPENING,
            ENTER_LOAN: Lang_B.BANKLOANS.ENTER_LOAN,
            ENTER_PRINCIPAL: Lang_B.BANKLOANS.ENTER_PRINCIPAL,
        },
        OTHERLIA: {
            HEADER: Lang_B.OTHERLIA.HEADER,
        },
        FOOD: {
            HEADER: Lang_B.FOOD.HEADER,
            ITEM1: Lang_B.FOOD.ITEM1,
            ITEM2: Lang_B.FOOD.ITEM2,
            ITEM3: Lang_B.FOOD.ITEM3,
        },
        HOUSING: {
            HEADER: Lang_B.HOUSING.HEADER,
            ITEM1: Lang_B.HOUSING.ITEM1,
        },
        AUTO: {
            HEADER: Lang_B.AUTO.HEADER,
            ITEM1: Lang_B.AUTO.ITEM1,
            ITEM2: Lang_B.AUTO.ITEM2,
        },
        HOUSEHOLD: {
            HEADER: Lang_B.HOUSEHOLD.HEADER,
            ITEM1: Lang_B.HOUSEHOLD.ITEM1,
            ITEM2: Lang_B.HOUSEHOLD.ITEM2,
            ITEM3: Lang_B.HOUSEHOLD.ITEM3,
            ITEM4: Lang_B.HOUSEHOLD.ITEM4,
        },
        EDUCATION: {
            HEADER: Lang_B.EDUCATION.HEADER,
            ITEM1: Lang_B.EDUCATION.ITEM1,
        },
        SPECIAL: {
            HEADER: Lang_B.SPECIAL.HEADER,
            ITEM1: Lang_B.SPECIAL.ITEM1,
            ITEM2: Lang_B.SPECIAL.ITEM2,
            ITEM3: Lang_B.SPECIAL.ITEM3,
            ITEM4: Lang_B.SPECIAL.ITEM4,
        },
        OTHEREXP: {
            HEADER: Lang_B.OTHEREXP.HEADER,
            ITEM1: Lang_B.OTHEREXP.ITEM1,
        },
        LOSSEXP: {
            HEADER: Lang_B.LOSSEXP.HEADER,
            ITEM1: Lang_B.LOSSEXP.ITEM1,
        },
        GIFTEXP: {
            HEADER: Lang_B.GIFTEXP.HEADER,
            ITEM1: Lang_B.GIFTEXP.ITEM1,
        },
        TAXCHARGES: {
            HEADER: Lang_B.TAXCHARGES.HEADER,
            ITEM1: Lang_B.TAXCHARGES.ITEM1,
            ITEM2: Lang_B.TAXCHARGES.ITEM2,
        },


        HOME: {
            TAGLINE_1: Lang_B.HOME.TAGLINE_1,
            TAGLINE_2: Lang_B.HOME.TAGLINE_2,
            NAME: Lang_B.HOME.NAME,
            OFFER_SECTION: Lang_B.HOME.OFFER_SECTION,
            OFFER_1: Lang_B.HOME.OFFER_1,
            OFFER_2: Lang_B.HOME.OFFER_2,
            OFFER_3: Lang_B.HOME.OFFER_3,
            OFFER_1_TEXT: Lang_B.HOME.OFFER_1_TEXT,
            OFFER_2_TEXT: Lang_B.HOME.OFFER_2_TEXT,
            OFFER_3_TEXT: Lang_B.HOME.OFFER_3_TEXT,
            CHOOSE_SECTION: Lang_B.HOME.CHOOSE_SECTION,
            CHOOSE_1: Lang_B.HOME.CHOOSE_1,
            CHOOSE_2: Lang_B.HOME.CHOOSE_2,
            CHOOSE_3: Lang_B.HOME.CHOOSE_3,
            CHOOSE_4: Lang_B.HOME.CHOOSE_4,
            CHOOSE_5: Lang_B.HOME.CHOOSE_5,
            CHOOSE_6: Lang_B.HOME.CHOOSE_6,
            CHOOSE_7: Lang_B.HOME.CHOOSE_7,
            CHOOSE_8: Lang_B.HOME.CHOOSE_8,
            CHOOSE_9: Lang_B.HOME.CHOOSE_9,
            CHOOSE_10: Lang_B.HOME.CHOOSE_10,
            CHOOSE_11: Lang_B.HOME.CHOOSE_11,
            CHOOSE_1_TEXT: Lang_B.HOME.CHOOSE_1_TEXT,
            CHOOSE_2_TEXT: Lang_B.HOME.CHOOSE_2_TEXT,
            CHOOSE_3_TEXT: Lang_B.HOME.CHOOSE_3_TEXT,
            CHOOSE_4_TEXT: Lang_B.HOME.CHOOSE_4_TEXT,
            CHOOSE_5_TEXT: Lang_B.HOME.CHOOSE_5_TEXT,
            CHOOSE_6_TEXT: Lang_B.HOME.CHOOSE_6_TEXT,
            CHOOSE_7_TEXT: Lang_B.HOME.CHOOSE_7_TEXT,
            CHOOSE_8_TEXT: Lang_B.HOME.CHOOSE_8_TEXT,
            CHOOSE_9_TEXT: Lang_B.HOME.CHOOSE_9_TEXT,
            CHOOSE_10_TEXT: Lang_B.HOME.CHOOSE_10_TEXT,
            CHOOSE_11_TEXT: Lang_B.HOME.CHOOSE_11_TEXT,
            V_TAG_1: Lang_B.HOME.V_TAG_1,
            V_TAG_2: Lang_B.HOME.V_TAG_2,
            V_TAG_3: Lang_B.HOME.V_TAG_3,
            V_TAG_4: Lang_B.HOME.V_TAG_4,
            V_TAG_5: Lang_B.HOME.V_TAG_5,
            V_TAG_6: Lang_B.HOME.V_TAG_6,
            V_TAG_7: Lang_B.HOME.V_TAG_7,
            V_TAG_8: Lang_B.HOME.V_TAG_8,

        },
        ABOUT: {
            HEADER: Lang_B.ABOUT.HEADER,
            TAGLINE: Lang_B.ABOUT.TAGLINE,
            ABOUT_1: Lang_B.ABOUT.ABOUT_1,
            ABOUT_2: Lang_B.ABOUT.ABOUT_2,
            ABOUT_3: Lang_B.ABOUT.ABOUT_3,
            ABOUT_4: Lang_B.ABOUT.ABOUT_4,
            ABOUT_5: Lang_B.ABOUT.ABOUT_5,
            ABOUT_6: Lang_B.ABOUT.ABOUT_6,
            CHOOSE_0: Lang_B.ABOUT.CHOOSE_0,
            CHOOSE_1: Lang_B.ABOUT.CHOOSE_1,
            CHOOSE_2: Lang_B.ABOUT.CHOOSE_2,
            CHOOSE_3: Lang_B.ABOUT.CHOOSE_3,
            CHOOSE_4: Lang_B.ABOUT.CHOOSE_4,
            CHOOSE_5: Lang_B.ABOUT.CHOOSE_5,
            CHOOSE_6: Lang_B.ABOUT.CHOOSE_6,
            CHOOSE_7: Lang_B.ABOUT.CHOOSE_7,
        },
        PRICING: {
            HEADER: Lang_B.PRICING.HEADER,
            SUB_HEADER: Lang_B.PRICING.SUB_HEADER,
            TABLE_HEADER_PLANS: Lang_B.PRICING.TABLE_HEADER_PLANS,
            TABLE_HEADER_2: Lang_B.PRICING.TABLE_HEADER_2,
            TABLE_HEADER_STANDARD: Lang_B.PRICING.TABLE_HEADER_STANDARD,
            TABLE_HEADER_PREMIUM: Lang_B.PRICING.TABLE_HEADER_PREMIUM,
            TABLE_HEADER_YEAR: Lang_B.PRICING.TABLE_HEADER_YEAR,
            TABLE_HEADER_FILE_YEAR: Lang_B.PRICING.TABLE_HEADER_FILE_YEAR,
            TABLE_HEADER_0: Lang_B.PRICING.TABLE_HEADER_0,
            TABLE_HEADER_300: Lang_B.PRICING.TABLE_HEADER_300,
            TABLE_HEADER_200: Lang_B.PRICING.TABLE_HEADER_200,
            TABLE_HEADER_800: Lang_B.PRICING.TABLE_HEADER_800,
            TABLE_HEADER_150: Lang_B.PRICING.TABLE_HEADER_150,
            TABLE_HEADER_250: Lang_B.PRICING.TABLE_HEADER_250,
            TABLE_HEADER_100: Lang_B.PRICING.TABLE_HEADER_100,
            TABLE_HEADER_PRO: Lang_B.PRICING.TABLE_HEADER_PRO,
            TABLE_HEADER_ENT: Lang_B.PRICING.TABLE_HEADER_ENT,
            TABLE_HEADER_5c: Lang_B.PRICING.TABLE_HEADER_5c,
            TABLE_HEADER_50c: Lang_B.PRICING.TABLE_HEADER_50c,
            TABLE_HEADER_100c: Lang_B.PRICING.TABLE_HEADER_100c,
            TABLE_HEADER_UN: Lang_B.PRICING.TABLE_HEADER_UN,
            TABLE_HEADER_FREE: Lang_B.PRICING.TABLE_HEADER_FREE,
            TABLE_HEADER_18: Lang_B.PRICING.TABLE_HEADER_18,
            TABLE_HEADER_19: Lang_B.PRICING.TABLE_HEADER_19,
            TABLE_HEADER_STARTER: Lang_B.PRICING.TABLE_HEADER_STARTER,
            TABLE_HEADER_499: Lang_B.PRICING.TABLE_HEADER_499,
            TABLE_HEADER_999: Lang_B.PRICING.TABLE_HEADER_999,
            TABLE_HEADER_1999: Lang_B.PRICING.TABLE_HEADER_1999,
            PLAN_1: Lang_B.PRICING.PLAN_1,
            PLAN_1_s: Lang_B.PRICING.PLAN_1_s,
            PLAN_2: Lang_B.PRICING.PLAN_2,
            PLAN_2_s: Lang_B.PRICING.PLAN_2_s,
            PLAN_TEXT_1: Lang_B.PRICING.PLAN_TEXT_1,
            PLAN_TEXT_2: Lang_B.PRICING.PLAN_TEXT_2,
            PLAN_TEXT_3: Lang_B.PRICING.PLAN_TEXT_3,
            PLAN_TEXT_4: Lang_B.PRICING.PLAN_TEXT_4,
            PLAN_TEXT_5: Lang_B.PRICING.PLAN_TEXT_5,
            PLAN_TEXT_6: Lang_B.PRICING.PLAN_TEXT_6,
            PLAN_TEXT_7: Lang_B.PRICING.PLAN_TEXT_7,
            PLAN_TEXT_8: Lang_B.PRICING.PLAN_TEXT_8,
            PLAN_TEXT_9: Lang_B.PRICING.PLAN_TEXT_9,
            PLAN_TEXT_9a: Lang_B.PRICING.PLAN_TEXT_9a,
            PLAN_TEXT_10: Lang_B.PRICING.PLAN_TEXT_10,
            PLAN_TEXT_11: Lang_B.PRICING.PLAN_TEXT_11,
            PLAN_TEXT_12: Lang_B.PRICING.PLAN_TEXT_12,
            PLAN_TEXT_13: Lang_B.PRICING.PLAN_TEXT_13,
            PLAN_TEXT_14: Lang_B.PRICING.PLAN_TEXT_14,
            PLAN_TEXT_15: Lang_B.PRICING.PLAN_TEXT_15,
            PLAN_TEXT_16: Lang_B.PRICING.PLAN_TEXT_16,
            PLAN_TEXT_17: Lang_B.PRICING.PLAN_TEXT_17,
            R1: Lang_B.PRICING.R1,
            R2: Lang_B.PRICING.R2,
            R3: Lang_B.PRICING.R3,
            R4: Lang_B.PRICING.R4,
            R5: Lang_B.PRICING.R5,
            R6: Lang_B.PRICING.R6,
            R7: Lang_B.PRICING.R7,
            R8: Lang_B.PRICING.R8,
            R9: Lang_B.PRICING.R9,
            R10: Lang_B.PRICING.R10,
            R11: Lang_B.PRICING.R11,
            R12: Lang_B.PRICING.R12,
            R13: Lang_B.PRICING.R13,
            R14: Lang_B.PRICING.R14,
            R15: Lang_B.PRICING.R15,

        },
        NEWS: {
            HEADER: Lang_B.NEWS.HEADER,
            HEADER_SUB_N: Lang_B.NEWS.HEADER_SUB_N,
            HEADER_SUB_O: Lang_B.NEWS.HEADER_SUB_O,
            HEADER_SUB_D: Lang_B.NEWS.HEADER_SUB_D,
        },
        BLOG: {
            HEADER: Lang_B.BLOG.HEADER,
            R1: Lang_B.BLOG.R1,
            R2: Lang_B.BLOG.R2,
            R3: Lang_B.BLOG.R3,
            R4: Lang_B.BLOG.R4,
            R5: Lang_B.BLOG.R5,
            R6: Lang_B.BLOG.R6,
            R7: Lang_B.BLOG.R7,
            R8: Lang_B.BLOG.R8,
            R9: Lang_B.BLOG.R9,

        },
        FAQ: {
            HEADER: Lang_B.FAQ.HEADER,
            R1: Lang_B.FAQ.R1,
            R2: Lang_B.FAQ.R2,
            TAX_01_Q: Lang_B.FAQ.TAX_01_Q,
            TAX_01_A: Lang_B.FAQ.TAX_01_A,
            TAX_02_Q: Lang_B.FAQ.TAX_02_Q,
            TAX_02_A: Lang_B.FAQ.TAX_02_A,
            TAX_03_Q: Lang_B.FAQ.TAX_03_Q,
            TAX_03_A: Lang_B.FAQ.TAX_03_A,
            TAX_04_Q: Lang_B.FAQ.TAX_04_Q,
            TAX_04_A: Lang_B.FAQ.TAX_04_A,
            TAX_05_Q: Lang_B.FAQ.TAX_05_Q,
            TAX_05_A: Lang_B.FAQ.TAX_05_A,
            TAX_06_Q: Lang_B.FAQ.TAX_06_Q,
            TAX_06_A: Lang_B.FAQ.TAX_06_A,
            TAX_07_Q: Lang_B.FAQ.TAX_07_Q,
            TAX_07_A: Lang_B.FAQ.TAX_07_A,
            TAX_08_Q: Lang_B.FAQ.TAX_08_Q,
            TAX_08_A: Lang_B.FAQ.TAX_08_A,
            TAX_09_Q: Lang_B.FAQ.TAX_09_Q,
            TAX_09_A: Lang_B.FAQ.TAX_09_A,
            TAX_10_Q: Lang_B.FAQ.TAX_10_Q,
            TAX_10_A: Lang_B.FAQ.TAX_10_A,
            TAX_11_Q: Lang_B.FAQ.TAX_11_Q,
            TAX_11_A: Lang_B.FAQ.TAX_11_A,
            TAX_12_Q: Lang_B.FAQ.TAX_12_Q,
            TAX_12_A: Lang_B.FAQ.TAX_12_A,
            TAX_13_Q: Lang_B.FAQ.TAX_13_Q,
            TAX_13_A: Lang_B.FAQ.TAX_13_A,
            TAX_14_Q: Lang_B.FAQ.TAX_14_Q,
            TAX_14_A: Lang_B.FAQ.TAX_14_A,
            GEN_01_Q: Lang_B.FAQ.GEN_01_Q,
            GEN_01_A: Lang_B.FAQ.GEN_01_A,
            GEN_02_Q: Lang_B.FAQ.GEN_02_Q,
            GEN_02_A: Lang_B.FAQ.GEN_02_A,
            GEN_03_Q: Lang_B.FAQ.GEN_03_Q,
            GEN_03_A: Lang_B.FAQ.GEN_03_A,
            GEN_04_Q: Lang_B.FAQ.GEN_04_Q,
            GEN_04_A: Lang_B.FAQ.GEN_04_A,
            GEN_05_Q: Lang_B.FAQ.GEN_05_Q,
            GEN_05_A: Lang_B.FAQ.GEN_05_A,
            GEN_06_Q: Lang_B.FAQ.GEN_06_Q,
            GEN_06_A: Lang_B.FAQ.GEN_06_A,
            GEN_07_Q: Lang_B.FAQ.GEN_07_Q,
            GEN_07_A: Lang_B.FAQ.GEN_07_A,
            GEN_08_Q: Lang_B.FAQ.GEN_08_Q,
            GEN_08_A: Lang_B.FAQ.GEN_08_A,
            GEN_09_Q: Lang_B.FAQ.GEN_09_Q,
            GEN_09_A: Lang_B.FAQ.GEN_09_A,
            GEN_10_Q: Lang_B.FAQ.GEN_10_Q,
            GEN_10_A: Lang_B.FAQ.GEN_10_A,
        },
        CONTACT: {
            HEADER: Lang_B.CONTACT.HEADER,
            HEADER_2: Lang_B.CONTACT.HEADER_2,
            HEADER_3: Lang_B.CONTACT.HEADER_3,
            CONTACT_US: Lang_B.CONTACT.CONTACT_US,
            EMAIL: Lang_B.CONTACT.EMAIL,
            LOCATION: Lang_B.CONTACT.LOCATION,
            HELPLINE: Lang_B.CONTACT.HELPLINE,
            ADDRESS: Lang_B.CONTACT.ADDRESS,
            R5: Lang_B.CONTACT.R5,
            R6: Lang_B.CONTACT.R6,
            R7: Lang_B.CONTACT.R7,
            ADDRESS_1: Lang_B.CONTACT.ADDRESS_1,
            ADDRESS_2: Lang_B.CONTACT.ADDRESS_2,
            ADDRESS_3: Lang_B.CONTACT.ADDRESS_3,
        },
        HOW_IT_WORKS: {
            HEADER: Lang_B.HOW_IT_WORKS.HEADER,
            HEADER_1: Lang_B.HOW_IT_WORKS.HEADER_1,
            HEADER_2: Lang_B.HOW_IT_WORKS.HEADER_2,
            TAB_1_HEADER: Lang_B.HOW_IT_WORKS.TAB_1_HEADER,
            TAB_1_ITEM_1: Lang_B.HOW_IT_WORKS.TAB_1_ITEM_1,
            TAB_1_ITEM_2: Lang_B.HOW_IT_WORKS.TAB_1_ITEM_2,
            TAB_1_ITEM_3: Lang_B.HOW_IT_WORKS.TAB_1_ITEM_3,
            TAB_2_HEADER: Lang_B.HOW_IT_WORKS.TAB_2_HEADER,
            TAB_2_ITEM_1: Lang_B.HOW_IT_WORKS.TAB_2_ITEM_1,
            TAB_2_ITEM_2: Lang_B.HOW_IT_WORKS.TAB_2_ITEM_2,
            TAB_2_ITEM_3: Lang_B.HOW_IT_WORKS.TAB_2_ITEM_3,
            TAB_2_ITEM_4: Lang_B.HOW_IT_WORKS.TAB_2_ITEM_4,
            TAB_2_ITEM_5: Lang_B.HOW_IT_WORKS.TAB_2_ITEM_5,
            TAB_3_HEADER: Lang_B.HOW_IT_WORKS.TAB_3_HEADER,
            TAB_3_ITEM_1: Lang_B.HOW_IT_WORKS.TAB_3_ITEM_1,
            TAB_3_ITEM_2: Lang_B.HOW_IT_WORKS.TAB_3_ITEM_2,
            TAB_3_ITEM_3: Lang_B.HOW_IT_WORKS.TAB_3_ITEM_3,
            TAB_4_HEADER: Lang_B.HOW_IT_WORKS.TAB_4_HEADER,
            TAB_4_ITEM_1: Lang_B.HOW_IT_WORKS.TAB_4_ITEM_1,
            TAB_4_ITEM_2: Lang_B.HOW_IT_WORKS.TAB_4_ITEM_2,
            TAB_4_ITEM_3: Lang_B.HOW_IT_WORKS.TAB_4_ITEM_3,
        },
        PROFILE: {
            HEADER: Lang_B.PROFILE.HEADER,
            OrgName: Lang_B.PROFILE.OrgName,
            ITPNo: Lang_B.PROFILE.ITPNo,
            BarMembershipNo: Lang_B.PROFILE.BarMembershipNo,
            Mobile: Lang_B.PROFILE.Mobile,
            Email: Lang_B.PROFILE.Email,
            Address1: Lang_B.PROFILE.Address1,
            Address2: Lang_B.PROFILE.Address2,
            ENTER_OrgName: Lang_B.PROFILE.ENTER_OrgName,
            ENTER_ITPNo: Lang_B.PROFILE.ENTER_ITPNo,
            ENTER_BarMembershipNo: Lang_B.PROFILE.ENTER_BarMembershipNo,
            ENTER_Mobile: Lang_B.PROFILE.ENTER_Mobile,
            ENTER_Email: Lang_B.PROFILE.ENTER_Email,
            ENTER_Address2: Lang_B.PROFILE.ENTER_Address2,
            ENTER_Profile: Lang_B.PROFILE.ENTER_Profile,

        },
        CLIENT: {
            HEADER: Lang_B.CLIENT.HEADER,
            ADD: Lang_B.CLIENT.ADD,
            ETIN: Lang_B.CLIENT.ETIN,
            AssesseeName: Lang_B.CLIENT.AssesseeName,
            Mobile: Lang_B.CLIENT.Mobile,
            Email: Lang_B.CLIENT.Email,
            ENTER_ETIN: Lang_B.CLIENT.ENTER_ETIN,
            ENTER_AssesseeName: Lang_B.CLIENT.ENTER_AssesseeName,
            ENTER_Mobile: Lang_B.CLIENT.ENTER_Mobile,
            ENTER_Email: Lang_B.CLIENT.ENTER_Email,
            TABLE_HEADER_ACTIONS: Lang_B.CLIENT.TABLE_HEADER_ACTIONS,
            TABLE_HEADER_PROGRESS: Lang_B.CLIENT.TABLE_HEADER_PROGRESS,
            TABLE_HEADER_ETIN: Lang_B.CLIENT.TABLE_HEADER_ETIN,
            TABLE_HEADER_NAME: Lang_B.CLIENT.TABLE_HEADER_NAME,
            TABLE_HEADER_MOBILE: Lang_B.CLIENT.TABLE_HEADER_MOBILE,
            TABLE_HEADER_EMAIL: Lang_B.CLIENT.TABLE_HEADER_EMAIL,
            TABLE_HEADER_TAX: Lang_B.CLIENT.TABLE_HEADER_TAX,
            TABLE_HEADER_TOTAL_DUES: Lang_B.CLIENT.TABLE_HEADER_TOTAL_DUES,
            TABLE_HEADER_REFERENCE: Lang_B.CLIENT.TABLE_HEADER_REFERENCE,
        },
        AUTH_TRAN: {
            BTN_LOGIN: Lang_B.AUTH_TRAN.BTN_LOGIN,
            BTN_LOGOUT: Lang_B.AUTH_TRAN.BTN_LOGOUT,
            BTN_LOGIN_S: Lang_B.AUTH_TRAN.BTN_LOGIN_S,
            BTN_REGISTER: Lang_B.AUTH_TRAN.BTN_REGISTER,
            LOGIN_HEADER: Lang_B.AUTH_TRAN.LOGIN_HEADER,
            PLACEHOLDER_NAME: Lang_B.AUTH_TRAN.PLACEHOLDER_NAME,
            PLACEHOLDER_EMAIL: Lang_B.AUTH_TRAN.PLACEHOLDER_EMAIL,
            PLACEHOLDER_PASSWORD: Lang_B.AUTH_TRAN.PLACEHOLDER_PASSWORD,
            PLACEHOLDER_N_PASSWORD: Lang_B.AUTH_TRAN.PLACEHOLDER_N_PASSWORD,
            PLACEHOLDER_C_PASSWORD: Lang_B.AUTH_TRAN.PLACEHOLDER_C_PASSWORD,
            FORGOT_PASSWORD: Lang_B.AUTH_TRAN.FORGOT_PASSWORD,
            TEXT_OR: Lang_B.AUTH_TRAN.TEXT_OR,
            TEXT_ACCEPT_TA: Lang_B.AUTH_TRAN.TEXT_ACCEPT_TA,
            TEXT_NO_ACCOUNT: Lang_B.AUTH_TRAN.TEXT_NO_ACCOUNT,
            TEXT_AL_ACCOUNT: Lang_B.AUTH_TRAN.TEXT_AL_ACCOUNT,
            TEXT_CREATE_ACCOUNT: Lang_B.AUTH_TRAN.TEXT_CREATE_ACCOUNT,
            TEXT_CREATE_ACCOUNT_IND: Lang_B.AUTH_TRAN.TEXT_CREATE_ACCOUNT_IND,
            TEXT_CREATE_ACCOUNT_PRO: Lang_B.AUTH_TRAN.TEXT_CREATE_ACCOUNT_PRO,
            REGISTER_HEADER: Lang_B.AUTH_TRAN.REGISTER_HEADER,
            TYPE_IND: Lang_B.AUTH_TRAN.TYPE_IND,
            TYPE_PRO: Lang_B.AUTH_TRAN.TYPE_PRO,
            TEXT_CONFIRM: Lang_B.AUTH_TRAN.TEXT_CONFIRM,
            TEXT_CONFIRM_EMAIL: Lang_B.AUTH_TRAN.TEXT_CONFIRM_EMAIL,
            TEXT_CONFIRM_INBOX: Lang_B.AUTH_TRAN.TEXT_CONFIRM_INBOX,
            TEXT_VERIFY_B: Lang_B.AUTH_TRAN.TEXT_VERIFY_B,
            TEXT_CONGRAT: Lang_B.AUTH_TRAN.TEXT_CONGRAT,
            TEXT_CONFIRMED: Lang_B.AUTH_TRAN.TEXT_CONFIRMED,
            TEXT_BACK_LOGIN: Lang_B.AUTH_TRAN.TEXT_BACK_LOGIN,
            TEXT_RECOVER: Lang_B.AUTH_TRAN.TEXT_RECOVER,
            TEXT_SEND_LINK: Lang_B.AUTH_TRAN.TEXT_SEND_LINK,
            TEXT_RESET: Lang_B.AUTH_TRAN.TEXT_RESET,
            TEXT_SET: Lang_B.AUTH_TRAN.TEXT_SET,

        },
        DOCUMENTS: {
            HEADER: Lang_B.DOCUMENTS.HEADER,
            HEADER_1: Lang_B.DOCUMENTS.HEADER_1,
            FILE_NAME: Lang_B.DOCUMENTS.FILE_NAME,
            SEARCH_1: Lang_B.DOCUMENTS.SEARCH_1,
            FILE_TH: Lang_B.DOCUMENTS.FILE_TH,
            FILE_T: Lang_B.DOCUMENTS.FILE_T,
            FILE_SIZE_TH: Lang_B.DOCUMENTS.FILE_SIZE_TH,
            UPLOAD_DATE_TH: Lang_B.DOCUMENTS.UPLOAD_DATE_TH,
            COMMENTS_TH: Lang_B.DOCUMENTS.COMMENTS_TH,
            ACTION_TH: Lang_B.DOCUMENTS.ACTION_TH,
            FILE_N: Lang_B.DOCUMENTS.FILE_N,
            DOC_ADD: Lang_B.DOCUMENTS.DOC_ADD,
            DOC_REP: Lang_B.DOCUMENTS.DOC_REP,
            DOC_ALL: Lang_B.DOCUMENTS.DOC_ALL,
            DOC_COMMENT_ADD: Lang_B.DOCUMENTS.DOC_COMMENT_ADD,
            DOC_Upload: Lang_B.DOCUMENTS.DOC_Upload,
            DOC_DRAG: Lang_B.DOCUMENTS.DOC_DRAG,
            DOC_OR: Lang_B.DOCUMENTS.DOC_OR,
            DOC_SELECT: Lang_B.DOCUMENTS.DOC_SELECT,
            DOC_DL_FILE: Lang_B.DOCUMENTS.DOC_DL_FILE,

        },
        ARCHIVE: {
            HEADER: Lang_B.ARCHIVE.HEADER,
            HEADER_1: Lang_B.ARCHIVE.HEADER_1,
            BUTTON_S: Lang_B.ARCHIVE.BUTTON_S,
            NOTICE_H: Lang_B.ARCHIVE.NOTICE_H,
            NOTICE_1: Lang_B.ARCHIVE.NOTICE_1,
            NOTICE_2: Lang_B.ARCHIVE.NOTICE_2,
            NOTICE_3: Lang_B.ARCHIVE.NOTICE_3,
            NOTICE_4: Lang_B.ARCHIVE.NOTICE_4,
        }
    }

};


let ff = {
    "Id": 91,
    "PractitionerId": 1,
    "AssesseeId": 2203,
    "ReturnId": 0,
    "ETIN": "99999999999",
    "AssesseeName": "Assessee Name",
    "MobileNo": "1911111111",
    "Email": "email@gmail.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
        {
            "AssesseeId": 2203,
            "ReturnId": 2190,
            "AcknowledgementSlipName": null,
            "TaxYear": "Year 2022-2023",
            "AmountToBePaid": null,
            "PaidWithReturn": null,
            "UploadedDate": "1900-01-01T00:00:00"
        }
    ]
}
