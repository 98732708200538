<div [formGroup]="form">

  <div fxLayout="column"
    fxLayoutAlign="start">

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">
      <div fxFlex="40"
        style="margin-right: 8px !important; "
        fxLayoutAlign="center center">
        <div style="font-weight: bold;color: #444444;">{{ 'SALARY.PAY_ALLOWANCE' | translate }}</div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.YEARLY_INCOME' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.Exempted_Income' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'SALARY.TAXABLE_INCOME' | translate }}
        </div>
      </div>
    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Basic_Pay' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Basic_Pay_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="BasicPayAmount"
          (keyup)="onFocusoutEvent($event,'BasicPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
        <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BasicPayExempted"
          (keyup)="onFocusoutEvent($event,'BasicPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BasicPayTaxable"
          (keyup)="onFocusoutEvent($event,'BasicPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Special_Pay' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Special_Pay_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="SpecialPayAmount"
          (keyup)="onFocusoutEvent($event,'SpecialPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="SpecialPayExempted"
          (keyup)="onFocusoutEvent($event,'SpecialPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="SpecialPayTaxable"
          (keyup)="onFocusoutEvent($event,'SpecialPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Dearness_Allowance' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Dearness_Allowance_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex=""
          formControlName="DearnessAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DearnessAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DearnessAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'DearnessAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Conveyance_Allowance' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.Conveyance_Allowance_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="ConveyanceAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ConveyanceAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ConveyanceAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'ConveyanceAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.H_R_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.H_R_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="HouseRentAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HouseRentAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HouseRentAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'HouseRentAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.M_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.M_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="MedicalAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="MedicalAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="MedicalAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'MedicalAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.A_S_S' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.A_S_S_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="AllowanceForSupportStaffAmount"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="AllowanceForSupportStaffExempted"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="AllowanceForSupportStaffTaxable"
          (keyup)="onFocusoutEvent($event,'AllowanceForSupportStaffTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.L_F_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.L_F_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="LeaveFairAssistanceStaffAmount"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveFairAssistanceStaffExempted"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveFairAssistanceStaffTaxable"
          (keyup)="onFocusoutEvent($event,'LeaveFairAssistanceStaffTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.L_E' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.L_E_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="LeaveEncashmentAmount"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveEncashmentExempted"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="LeaveEncashmentTaxable"
          (keyup)="onFocusoutEvent($event,'LeaveEncashmentTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.HON' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.HON_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="HonorariumOrRewardAmount"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HonorariumOrRewardExempted"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HonorariumOrRewardTaxable"
          (keyup)="onFocusoutEvent($event,'HonorariumOrRewardTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OV_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OV_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="OvertimeAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OvertimeAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OvertimeAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'OvertimeAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.BON' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.BON_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="BonusAmount"
          (keyup)="onFocusoutEvent($event,'BonusAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BonusExempted"
          (keyup)="onFocusoutEvent($event,'BonusExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BonusTaxable"
          (keyup)="onFocusoutEvent($event,'BonusTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OTH_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OTH_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="OtherAllowancesAmount"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OtherAllowancesExempted"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OtherAllowancesTaxable"
          (keyup)="onFocusoutEvent($event,'OtherAllowancesTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.E_C_P_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.E_C_P_F_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="EmployersContributionProvidentFundAmount"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="EmployersContributionProvidentFundExempted"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="EmployersContributionProvidentFundTaxable"
          (keyup)="onFocusoutEvent($event,'EmployersContributionProvidentFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_A_P_F' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_A_P_F_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestAccruedProvidentFundRateOfInterestAmount"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestAccruedProvidentFundRateOfInterestExempted"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestAccruedProvidentFundRateOfInterestTaxable"
          (keyup)="onFocusoutEvent($event,'InterestAccruedProvidentFundRateOfInterestTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Deemed_Income_Transport' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="DeemedIncomeTransportAmount"
          (keyup)="onFocusoutEvent($event,'DeemedIncomeTransportAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DeemedIncomeTransportExempted"
          (keyup)="onFocusoutEvent($event,'DeemedIncomeTransportExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DeemedIncomeTransportTaxable"
          (keyup)="onFocusoutEvent($event,'DeemedIncomeTransportTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Deemed_Free_Accommodation' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="EmployerPaidRentalAmount"
          (keyup)="onFocusoutEvent($event,'EmployerPaidRentalAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="DeemedFreeAccommodationExempted"
          (keyup)="onFocusoutEvent($event,'DeemedFreeAccommodationExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="FurnishedOrUnfurnishedHouseFromEmployerTaxable"
          (keyup)="onFocusoutEvent($event,'FurnishedOrUnfurnishedHouseFromEmployerTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Festival_Bonus' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="FestivalBonusAmount"
          (keyup)="onFocusoutEvent($event,'FestivalBonusAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="FestivalBonusExempted"
          (keyup)="onFocusoutEvent($event,'FestivalBonusExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="FestivalBonusTaxable"
          (keyup)="onFocusoutEvent($event,'FestivalBonusTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Bengali_NewYear_Bonus' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="BengaliNewYearBonusAmount"
          (keyup)="onFocusoutEvent($event,'BengaliNewYearBonusAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BengaliNewYearBonusExempted"
          (keyup)="onFocusoutEvent($event,'BengaliNewYearBonusExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="BengaliNewYearBonusTaxable"
          (keyup)="onFocusoutEvent($event,'BengaliNewYearBonusTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Festival_Allowance' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="FestivalAllowanceAmount"
          (keyup)="onFocusoutEvent($event,'FestivalAllowanceAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="FestivalAllowanceExempted"
          (keyup)="onFocusoutEvent($event,'FestivalAllowanceExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="FestivalAllowanceTaxable"
          (keyup)="onFocusoutEvent($event,'FestivalAllowanceTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.PEN' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.PEN_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="PensionAmount"
          (keyup)="onFocusoutEvent($event,'PensionAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="PensionExempted"
          (keyup)="onFocusoutEvent($event,'PensionExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="PensionTaxable"
          (keyup)="onFocusoutEvent($event,'PensionTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.I_P_S_A' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.I_P_S_A_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable"
          (keyup)="onFocusoutEvent($event,'ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.OTH' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.OTH_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="OthersAmount"
          (keyup)="onFocusoutEvent($event,'OthersAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OthersExempted"
          (keyup)="onFocusoutEvent($event,'OthersExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OthersTaxable"
          (keyup)="onFocusoutEvent($event,'OthersTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.ARR_P' | translate }} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.ARR_P_IC' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="ArrearPayAmount"
          (keyup)="onFocusoutEvent($event,'ArrearPayAmount')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ArrearPayExempted"
          (keyup)="onFocusoutEvent($event,'ArrearPayExempted')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ArrearPayTaxable"
          (keyup)="onFocusoutEvent($event,'ArrearPayTaxable')"
          (click)="$event.target.select()"
          class="rightA"
          autocomplete="off"
          matInput>
      </mat-form-field>

    </div>
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 3px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important; font-weight: bold;">
        {{ 'SALARY.TAXABLE_INCOME' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalAmount"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalExempted"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="NetTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-bottom: 10px; padding-top: 20px; font-weight: bold;"
      fxFlex="1 0 auto">
      {{ 'SALARY.R3' | translate }}
    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Organization_Name' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T1' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">
        <input type="text"
          formControlName="OrganizationName"
          class="leftA"
          autocomplete="off"
          matInput>
        <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Organization_Address' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T2' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">

        <textarea style="resize:vertical"
          rows="2"
          type="text"
          formControlName="OrganizationAddress"
          class="leftA"
          autocomplete="off"
          matInput>
      </textarea>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; "
      fxFlex="1 0 auto">

      <div fxFlex="30"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'SALARY.Buisness_Identification_No' | translate }}<mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'SALARY.T3' | translate }}"
          matSuffix>info</mat-icon>
      </div>

      <mat-form-field appearance="outline"
        fxFlex="70"
        style="margin-right: 8px !important;">
        <input formControlName="BIN"
          maxlength="9"
          minlength="9"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          class="leftA"
          autocomplete="off"
          matInput>
        <!-- <mat-error> {{ 'COMMON.BUTTON_REQ' |
          translate }}</mat-error> -->
      </mat-form-field>

    </div>
  </div>
</div>
