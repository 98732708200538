import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';

import { FormGroup, } from '@angular/forms';

import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { TaxRebateService } from './TaxRebate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/auth';
import Utils from 'app/util/utils';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';


@Component({
    selector: 'TaxRebate',
    templateUrl: './TaxRebate.component.html',
    styleUrls: ['./TaxRebate.component.scss']
})
export class TaxRebateComponent
{
    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(

        private _formControlService: TaxRebateService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _authService: AuthenticationService,
        private sidePanelDeleteService: SidePanelDeleteService,

        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Tax Rebate" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Tax Rebate" }
        ]);
        this.setTitle("digiTax | Tax Rebate");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');
        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;
            this.form = this._formControlService.toFormGroup(this.controls);
        });
        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }

    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    OnSubmit(param): void
    {
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');


        let obj = this.form.getRawValue();

        if (obj.IsLateSubmit == '' || obj.IsLateSubmit == null)
        {
            obj.IsLateSubmit = false;
        }


        if (obj.LifeInsurancePolicyValue == "" || isNaN(Number(obj.LifeInsurancePolicyValue)) == true)
        {
            obj.LifeInsurancePolicyValue = 0
        }
        if (obj.LifeInsurancePremiumAmount == "" || isNaN(Number(obj.LifeInsurancePremiumAmount)) == true)
        {
            obj.LifeInsurancePremiumAmount = 0
        }
        if (obj.LifeInsurancePremiumAllowed == "" || isNaN(Number(obj.LifeInsurancePremiumAllowed)) == true)
        {
            obj.LifeInsurancePremiumAllowed = 0
        }

        if (obj.ContributionToDepositPensionSchemeAmount == "" || isNaN(Number(obj.ContributionToDepositPensionSchemeAmount)) == true)
        {
            obj.ContributionToDepositPensionSchemeAmount = 0
        }
        if (obj.ContributionToDepositPensionSchemeAllowed == "" || isNaN(Number(obj.ContributionToDepositPensionSchemeAllowed)) == true)
        {
            obj.ContributionToDepositPensionSchemeAllowed = 0
        }

        if (obj.InvestmentInApprovedSavingsCertificateAmount == "" || isNaN(Number(obj.InvestmentInApprovedSavingsCertificateAmount)) == true)
        {
            obj.InvestmentInApprovedSavingsCertificateAmount = 0
        }
        if (obj.InvestmentInApprovedSavingsCertificateAllowed == "" || isNaN(Number(obj.InvestmentInApprovedSavingsCertificateAllowed)) == true)
        {
            obj.InvestmentInApprovedSavingsCertificateAllowed = 0
        }

        if (obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount == "" || isNaN(Number(obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount)) == true)
        {
            obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = 0
        }
        if (obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed == "" || isNaN(Number(obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)) == true)
        {
            obj.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = 0
        }

        if (obj.ContributionToProvidentFundToWhichProvidentFundAmount == "" || isNaN(Number(obj.ContributionToProvidentFundToWhichProvidentFundAmount)) == true)
        {
            obj.ContributionToProvidentFundToWhichProvidentFundAmount = 0
        }
        if (obj.ContributionToProvidentFundToWhichProvidentFundAllowed == "" || isNaN(Number(obj.ContributionToProvidentFundToWhichProvidentFundAllowed)) == true)
        {
            obj.ContributionToProvidentFundToWhichProvidentFundAllowed = 0
        }

        if (obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount == "" || isNaN(Number(obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount)) == true)
        {
            obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = 0
        }
        if (obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed == "" || isNaN(Number(obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)) == true)
        {
            obj.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = 0
        }

        if (obj.ContributionToSuperAnnuationFundAmount == "" || isNaN(Number(obj.ContributionToSuperAnnuationFundAmount)) == true)
        {
            obj.ContributionToSuperAnnuationFundAmount = 0
        }
        if (obj.ContributionToSuperAnnuationFundAllowed == "" || isNaN(Number(obj.ContributionToSuperAnnuationFundAllowed)) == true)
        {
            obj.ContributionToSuperAnnuationFundAllowed = 0
        }

        if (obj.ContributionToBenevolentFundAndGroupInsurancePremiumAmount == "" || isNaN(Number(obj.ContributionToBenevolentFundAndGroupInsurancePremiumAmount)) == true)
        {
            obj.ContributionToBenevolentFundAndGroupInsurancePremiumAmount = 0
        }
        if (obj.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed == "" || isNaN(Number(obj.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)) == true)
        {
            obj.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = 0
        }

        if (obj.InvestmentInBangladeshGovtTreasuryBondAmount == "" || isNaN(Number(obj.InvestmentInBangladeshGovtTreasuryBondAmount)) == true)
        {
            obj.InvestmentInBangladeshGovtTreasuryBondAmount = 0
        }
        if (obj.InvestmentInBangladeshGovtTreasuryBondAllowed == "" || isNaN(Number(obj.InvestmentInBangladeshGovtTreasuryBondAllowed)) == true)
        {
            obj.InvestmentInBangladeshGovtTreasuryBondAllowed = 0
        }

        if (obj.MutualFundAmount == "" || isNaN(Number(obj.MutualFundAmount)) == true)
        {
            obj.MutualFundAmount = 0
        }
        if (obj.MutualFundAllowed == "" || isNaN(Number(obj.MutualFundAllowed)) == true)
        {
            obj.MutualFundAllowed = 0
        }

        if (obj.ContributionToZakatFundAmount == "" || isNaN(Number(obj.ContributionToZakatFundAmount)) == true)
        {
            obj.ContributionToZakatFundAmount = 0
        }
        if (obj.ContributionToZakatFundAllowed == "" || isNaN(Number(obj.ContributionToZakatFundAllowed)) == true)
        {
            obj.ContributionToZakatFundAllowed = 0
        }

        if (obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount == "" || isNaN(Number(obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount)) == true)
        {
            obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = 0
        }
        if (obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed == "" || isNaN(Number(obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)) == true)
        {
            obj.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = 0
        }

        if (obj.DonationToCharitableHospitalRecognizedByNBRAmount == "" || isNaN(Number(obj.DonationToCharitableHospitalRecognizedByNBRAmount)) == true)
        {
            obj.DonationToCharitableHospitalRecognizedByNBRAmount = 0
        }

        if (obj.DonationToCharitableHospitalRecognizedByNBRAllowed == "" || isNaN(Number(obj.DonationToCharitableHospitalRecognizedByNBRAllowed)) == true)
        {
            obj.DonationToCharitableHospitalRecognizedByNBRAllowed = 0
        }

        if (obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount == "" || isNaN(Number(obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount)) == true)
        {
            obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = 0
        }
        if (obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed == "" || isNaN(Number(obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)) == true)
        {
            obj.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = 0
        }

        if (obj.ContributionToLiberationWarMusuemAmount == "" || isNaN(Number(obj.ContributionToLiberationWarMusuemAmount)) == true)
        {
            obj.ContributionToLiberationWarMusuemAmount = 0
        }
        if (obj.ContributionToLiberationWarMusuemAllowed == "" || isNaN(Number(obj.ContributionToLiberationWarMusuemAllowed)) == true)
        {
            obj.ContributionToLiberationWarMusuemAllowed = 0
        }

        if (obj.ContributionToAgaKahanDeveleopmentNetworkAmount == "" || isNaN(Number(obj.ContributionToAgaKahanDeveleopmentNetworkAmount)) == true)
        {
            obj.ContributionToAgaKahanDeveleopmentNetworkAmount = 0
        }
        if (obj.ContributionToAgaKahanDeveleopmentNetworkAllowed == "" || isNaN(Number(obj.ContributionToAgaKahanDeveleopmentNetworkAllowed)) == true)
        {
            obj.ContributionToAgaKahanDeveleopmentNetworkAllowed = 0
        }

        if (obj.DoantionToAhsaniaCancerHospitalAmount == "" || isNaN(Number(obj.DoantionToAhsaniaCancerHospitalAmount)) == true)
        {
            obj.DoantionToAhsaniaCancerHospitalAmount = 0
        }
        if (obj.DoantionToAhsaniaCancerHospitalAllowed == "" || isNaN(Number(obj.DoantionToAhsaniaCancerHospitalAllowed)) == true)
        {
            obj.DoantionToAhsaniaCancerHospitalAllowed = 0
        }

        if (obj.DonationToICDDRBAmount == "" || isNaN(Number(obj.DonationToICDDRBAmount)) == true)
        {
            obj.DonationToICDDRBAmount = 0
        }
        if (obj.DonationToICDDRBAllowed == "" || isNaN(Number(obj.DonationToICDDRBAllowed)) == true)
        {
            obj.DonationToICDDRBAllowed = 0
        }

        if (obj.DonationToCentreForRehabilitationOfParalyzedAmount == "" || isNaN(Number(obj.DonationToCentreForRehabilitationOfParalyzedAmount)) == true)
        {
            obj.DonationToCentreForRehabilitationOfParalyzedAmount = 0
        }
        if (obj.DonationToCentreForRehabilitationOfParalyzedAllowed == "" || isNaN(Number(obj.DonationToCentreForRehabilitationOfParalyzedAllowed)) == true)
        {
            obj.DonationToCentreForRehabilitationOfParalyzedAllowed = 0
        }

        if (obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount == "" || isNaN(Number(obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount)) == true)
        {
            obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = 0
        }
        if (obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed == "" || isNaN(Number(obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)) == true)
        {
            obj.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = 0
        }

        if (obj.ContributionToAsiatecSocietyBangladeshAmount == "" || isNaN(Number(obj.ContributionToAsiatecSocietyBangladeshAmount)) == true)
        {
            obj.ContributionToAsiatecSocietyBangladeshAmount = 0
        }
        if (obj.ContributionToAsiatecSocietyBangladeshAllowed == "" || isNaN(Number(obj.ContributionToAsiatecSocietyBangladeshAllowed)) == true)
        {
            obj.ContributionToAsiatecSocietyBangladeshAllowed = 0
        }

        if (obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount == "" || isNaN(Number(obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount)) == true)
        {
            obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = 0
        }
        if (obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed == "" || isNaN(Number(obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed)) == true)
        {
            obj.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = 0
        }

        if (obj.TotalInvestmentAmount == "" || isNaN(Number(obj.TotalInvestmentAmount)) == true)
        {
            obj.TotalInvestmentAmount = '0'
        }
        if (obj.TotalInvestmentAllowed == "" || isNaN(Number(obj.TotalInvestmentAllowed)) == true)
        {
            obj.TotalInvestmentAllowed = '0'
        }

        let stringed = JSON.stringify(obj);


        // console.log(stringed, 'stringed');
        if (obj.TotalInvestmentAmount == "" || obj.TotalInvestmentAmount == 0)
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (stringed != null)
            {
                this._formControlService.submitTaxRebate(stringed, this.id)
                    .subscribe((data: any) =>
                    {
                        if (data != null)
                        {
                            this._spinner.hide();
                        }

                        if (data.isError === false)
                        {
                            console.log('submit succesfull');
                        }
                        else
                        {

                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        if (data.isError === false && data.responseCode == "200")
                        {
                            if (this.id != null)
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }


                            if (param == 'next')
                            {
                                this._router.navigate(['/TaxDeductedAtSource']);
                            }
                            if (param == 'more')
                            {
                                this.sidePanelDeleteService.changeMessage(true);
                                this.form.reset();
                            }
                            if (param == 'exit')
                            {
                                this._authService.Logout();
                            }


                        }
                    }, (error: any) =>
                    {
                        console.log(error, 'error');
                    });
            }
        }
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    if (url == '/TaxDeductedAtSource')
                    {
                        this._router.navigate([url]);
                    } else
                    {
                        this.getIncomeMenuLast();
                    }
                    // this._router.navigate([url]);

                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            if (url == '/TaxDeductedAtSource')
            {
                this._router.navigate([url]);
            } else
            {
                this.getIncomeMenuLast();
            }
            // this._router.navigate([url]);
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    getIncomeMenuLast(): void
    {
        let routeMenu = null;

        let stepMenu = JSON.parse(localStorage.getItem('stepMenu'));
        if (stepMenu)
        {
            routeMenu = stepMenu.incomeMenu;
        }
        let lastElement = routeMenu[routeMenu.length - 1];

        // console.log(lastElement, 'lastElement');

        this._router.navigate([lastElement]);

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
